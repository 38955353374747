<template>
  <OpenImageModal v-model:imgUrl = openImgUrl />
  <div id="main">
    <div class="modal fade" id="PopUpMain" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
      aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <PopUpMain />
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="PopUpCheckList" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
      aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <PopUpCheckList />
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="PopUpQuestions" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
      aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <PopUpQuestions />
          </div>
        </div>
      </div>
    </div>
    <div class="header-slide">
      <div class="header-name">
        <img src="../assets/img/name_header.png" alt="#">
      </div>
      <div class="header-video">
        <img src="../assets/img/video_header.png" alt="#">
      </div>
      <div class="header-link">
        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#PopUpMain"
          style="text-decoration: none; color: #FFF"> Оставить заявку</button>
      </div>
    </div>
  </div>
  <div class="container">
    <main>
      <div class="first-block" id="projects">
        <div class="text-flex-container">
          <p style="color: #000;" class="mb-auto">ПРОЕКТЫ</p>
          <p style="color: #000" class="nineth-container-right-text w-50 mb-4">СК ЭЛИТ — ваш надежный партнер в ремонте коммерческих помещений! Мы беремся за проекты любой сложности — от уютных кафе до масштабных бизнес-центров. Наша команда сотрудничает как с крупными компаниями, так и с маленькими бизнесами, гарантируя качественную работу и доступные цены</p>
        </div>
        
        <div class="block-projects">
          <div class="main-block-project">
            <button @click="goToProjects('Ремонт%20офисов%20и%20коммерческой%20недвижимости')" class="project-main">
              <div class="project-container" :style="{ backgroundImage: `url(${imageFirst})` }">
                <div class="project">
                  <div class="name-project">
                    <p>Ремонт офисов и коммерческой недвижимости</p>
                  </div>
                  <div class="more-details">
                    <img src="../assets/img/traingle.png" alt="">
                    <a href="/projects">Подробнее</a>
                  </div>
                </div>
              </div>
            </button>
            <button @click="goToProjects('Ремонт%20магазинов%20и%20шоурумов')" class="project-main">
              <div class="project-container" :style="{ backgroundImage: `url(${imageSecond})` }">
                <div class="project">
                  <div class="name-project">
                    <p>Ремонт магазинов и шоурумов</p>
                  </div>
                  <div class="more-details">
                    <img src="../assets/img/traingle.png" alt="">
                    <a href="/projects">Подробнее</a>
                  </div>
                </div>
              </div>
            </button>
            <button @click="goToProjects('Ремонт%20ресторанов%20и%20баров')" class="project-main">
              <div class="project-container" :style="{ backgroundImage: `url(${imageThird})` }">
                <div class="project">
                  <div class="name-project">
                    <p>Ремонт ресторанов и баров</p>
                  </div>
                  <div class="more-details">
                    <img src="../assets/img/traingle.png" alt="">
                    <a href="/projects/Ремонт%20ресторанов%20и%20баров">Подробнее</a>
                  </div>
                </div>
              </div>
            </button>
            <button @click="goToProjects('Ремонт%20кинотеатров')" class="project-main">
              <div class="project-container" :style="{ backgroundImage: `url(${imageFourth})` }">
                <div class="project">
                  <div class="name-project">
                    <p>Ремонт кинотеатров</p>
                  </div>
                  <div class="more-details">
                    <img src="../assets/img/traingle.png" alt="">
                    <a href="/projects">Подробнее</a>
                  </div>
                </div>
              </div>
            </button>
            <button @click="goToProjects('Ремонт%20бизнес-центров')" class="project-main">
              <div class="project-container" :style="{ backgroundImage: `url(${imageFifth})` }">
                <div class="project">
                  <div class="name-project">
                    <p>Ремонт бизнес-центров</p>
                  </div>
                  <div class="more-details">
                    <img src="../assets/img/traingle.png" alt="">
                    <a href="/projects">Подробнее</a>
                  </div>
                </div>
              </div>
            </button>
            <button @click="goToProjects('Ремонт%20салонов%20красоты%20и%20СПА')" class="project-main">
              <div class="project-container" :style="{ backgroundImage: `url(${imageSixth})` }">
                <div class="project">
                  <div class="name-project">
                    <p>Ремонт салонов красоты и СПА</p>
                  </div>
                  <div class="more-details">
                    <img src="../assets/img/traingle.png" alt="">
                    <a href="/projects">Подробнее</a>
                  </div>
                </div>
              </div>
            </button>
            <button @click="goToProjects('Ремонт%20банков')" class="project-main">
              <div class="project-container" :style="{ backgroundImage: `url(${imageSeventh})` }">
                <div class="project">
                  <div class="name-project">
                    <p>Ремонт банков</p>
                  </div>
                  <div class="more-details">
                    <img src="../assets/img/traingle.png" alt="">
                    <a href="/projects">Подробнее</a>
                  </div>
                </div>
              </div>
            </button>
            <button @click="goToProjects('Ремонт%20гостиниц')" class="project-main">
              <div class="project-container" :style="{ backgroundImage: `url(${imageEighth})` }">
                <div class="project">
                  <div class="name-project">
                    <p>Ремонт гостиниц</p>
                  </div>
                  <div class="more-details">
                    <img src="../assets/img/traingle.png" alt="">
                    <a href="/projects">Подробнее</a>
                  </div>
                </div>
              </div>
            </button>
            <button @click="goToProjects('Ремонт%20производственных%20помещений')" class="project-main">
              <div class="project-container" :style="{ backgroundImage: `url(${imageNineth})` }">
                <div class="project">
                  <div class="name-project">
                    <p>Ремонт производственных помещений</p>
                  </div>
                  <div class="more-details">
                    <img src="../assets/img/traingle.png" alt="">
                    <a href="/projects">Подробнее</a>
                  </div>
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>

      <GetEstimates />



      <div class="third-block" id="advantages">
        <div class="block-advantages">
          <div class="first-block-advantages">
            <div class="advantage-1">
              <div class="advantage">
                <div class="name-advantage">
                  <p>ПРЕИМУЩЕСТВА РАБОТЫ С НАМИ</p>
                </div>
                <div class="our-goal">
                  <p>Наша <span class="black"> цель </span> – не просто выполнить объект, но и обеспечить <span
                      class="red">долгосрочное сотрудничество</span> с нашими клиентами</p>
                </div>
              </div>
            </div>

            <div class="advantage-2">
              <div class="advantage">
                <div class="name-advantage">
                  <p>Высокое качество выполнения работ</p>
                </div>
                <div class="our-goal">
                  <p>Используем лучшие материалы и современные технологии, чтобы обеспечить долговечность и надежность
                    всех выполненных работ</p>
                </div>
              </div>
            </div>

            <div class="advantage-3">
              <div class="advantage">
                <div class="name-advantage">
                  <p>Соблюдение сроков</p>
                </div>
                <div class="our-goal">
                  <p>Мы ценим ваше время и строго придерживаемся согласованных сроков выполнения работ</p>
                </div>
              </div>
            </div>
          </div>
          <div class="second-block-advantages">
            <div class="advantage-4">
              <div class="advantage">
                <div class="name-advantage">
                  <p>Опыт и репутация</p>
                </div>
                <div class="our-goal">
                  <p>Мы заработали доверие и уважение наших клиентов, работаю более 7 лет на рынке коммерческой
                    недвижимости</p>
                </div>
              </div>
            </div>

            <div class="advantage-5">
              <div class="advantage">
                <div class="name-advantage">
                  <p>Гарантии и поддержка</p>
                </div>
                <div class="our-goal">
                  <p>Гарантия на наши работы 2 года, а также постгарантийное обслуживание</p>
                </div>
              </div>
            </div>

            <div class="advantage-6">
              <div class="advantage">
                <div class="name-advantage">
                  <p>Собственное мебельное <br> производство</p>
                </div>
              </div>
            </div>
          </div>
          <div class="third-block-link">
            <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#PopUpMain">Рассчитать
              проект</button>
          </div>
        </div>
      </div>

      <CheckList />

      <div class="fifth-block" id="clients">
        <div class="text-flex-container">
          <p style="color: #000;" class="mb-auto">НАМ ДОВЕРЯЮТ</p>
          <p style="color: #000" class="nineth-container-right-text w-50 mb-4">СК ЭЛИТ гордится тем, что сотрудничает с множеством известных компаний и брендов, включая Сбербанк, ПСБ, Синемапарк, Ашан и ТЦ Авиапарк. Мы работаем с различными организациями, от крупных сетей до динамичных стартапов. Наша команда всегда готова создавать уникальные решения, соответствующие требованиям каждого клиента.</p>
        </div>

        <div class="slider" ref="slider">
          <div class="slider-list" ref="sliderList">
            <div class="slider-track" style="background: none !important;-webkit-user-select: none; -moz-user-select: none; -ms-user-select: none;" :style="{ transform: `translate3d(${transform}px, 0px, 0px)` }" ref="sliderTrack">
              <div class="slide " v-for="(slide, index) in slides" :key="index" :ref="'slide' + index">
                <div class="slide-hover-border">
                <div class="slide-inner-hover-border">
                  <img :src="require(`@/assets/img/${slide.img}`)" alt="" draggable="false" style="width: 250px;">
                </div>
                </div> 
              </div>
            </div>
            <div class="slider-track" style="height: 100%; z-index: -1; width: 1000%; left: -250%; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none;" :style="{ transform: `translate3d(${-transform}px, 0px, 0px)` }">
              <img src="" alt="">
            </div>
          </div>
        </div>
      </div>


      <div class="sixth-block" style="max-width: 100%; height: 100%;">
        <div class="text-flex-container">
          <p style="color: #000;" class="mb-auto">КАРТА НАШИХ ОБЪЕКТОВ</p>
          <p style="color: #000" class="nineth-container-right-text w-50 mb-4">На нашей интерактивной карте вы можете ознакомиться с проектами, которые мы реализовали на территории России. Каждый маркер на карте указывает на объект, где мы провели ремонт, и при нажатии на него вы сможете получить подробную информацию о выполненных работах и особенностях каждого проекта.</p>
        </div>

        <div class="w-75 mx-auto"><MainMap /></div>
        
        <!--<iframe
          src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d2396.889919547388!2d37.623533138211855!3d55.757803754581666!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sru!2sru!4v1723597973860!5m2!1sru!2sru"
          width="100%" height="569" style="border:0;" loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"></iframe>-->
      </div>
      <div class="seventh-block">
        <div class="text-flex-container mb-4">
          <p style="color: #000;" class="mb-auto">ПОЧЕМУ ВЫБИРАЮТ НАС</p>
          <p style="color: #000" class="nineth-container-right-text w-50">СК ЭЛИТ зарекомендовала себя как надежный партнер в сфере ремонта коммерческих помещений благодаря следующим факторам:</p>
        </div>

        <div class="seventh-containers-block">
          <div class="seventh-containers-1">
            <div class="seventh-first-container-1">
              <img src="../assets/img/seventh-1.png" alt="">
              <p>Работаем по ГОСТу и СНИПам</p>
            </div>
            <div class="seventh-second-container-1">
              <img src="../assets/img/seventh-2.png" alt="">
              <p>Наша компания имеет необходимые сертификаты, допуски, СРО</p>
            </div>
            <div class="seventh-third-container-1">
              <img src="../assets/img/seventh-3.png" alt="">
              <p>Бесплатный детальный подсчёт сметы онлайн</p>
            </div>
            <div class="seventh-fourth-container-1">
              <img src="../assets/img/seventh-4.png" alt="">
              <p>В нашей команде работают профессионалы узкого профиля</p>
            </div>
          </div>

          <div class="seventh-containers-2">
            <div class="seventh-first-container-2">
              <img src="../assets/img/seventh-5.png" alt="">
              <p>Реализуем проекты в полном соответствии с вашей идеей</p>
            </div>
            <div class="seventh-second-container-2">
              <img src="../assets/img/seventh-6.png" alt="">
              <p>Работаем без выходных</p>
            </div>
            <div class="seventh-third-container-2">
              <img src="../assets/img/seventh-7.png" alt="">
              <p>Ежедневно присылаем фото- и видеоотчёт с вашего объекта</p>
            </div>
            <button type="button" class="btn btn-primary seventh-fourth-container-2" data-bs-toggle="modal"
              data-bs-target="#PopUpMain">
              <p class="fs-5">+7 (495) 172-36-93</p>
              <p>Запросить консультацию</p>
            </button>
          </div>
        </div>
      </div>
      <div class="eighth-block" id="command">
        <div class="text-flex-container d-block">
          <p style="color: #000;" class="mb-4">КОМАНДА <br> SK ELITE</p>
          <p style="color: #000" class="nineth-container-right-text text-start mb-4">Наша команда состоит из профессионалов, объединенных общей целью: создавать качественные и стильные коммерческие пространства. В состав команды входят:</p>
        </div>

        <div id="carouselExample" class="carousel slide workers">
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="workers-container">
                <div class="worker">
                  <img src="../assets/img/Kirill.png" alt="">
                  <p class="workers-name">Скрипниченко Кирилл Сергеевич</p>
                  <p class="workers-job">Генеральный директор</p>
                </div>
                <div class="worker">
                  <img src="../assets/img/Miroslav.png" alt="">
                  <p class="workers-name">Мосин Мирослав Кириллович</p>
                  <p class="workers-job">Руководитель отдела кадров</p>
                </div>
                <div class="worker">
                  <img src="../assets/img/Vlad.png" alt="">
                  <p class="workers-name">Королев Владислав Александрович</p>
                  <p class="workers-job">Технический директор</p>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="workers-container">
                <div class="worker">
                  <img src="../assets/img/Egor.png" alt="">
                  <p class="workers-name">Булгаков Егор Витальевич</p>
                  <p class="workers-job">Заместитель технического директора</p>
                </div>
                <div class="worker">
                  <img src="../assets/img/Rose.png" alt="">
                  <p class="workers-name">Исаева Роза Исаевна </p>
                  <p class="workers-job">руководитель отдела маркетинга</p>
                </div>
                <div class="worker">
                  <img src="../assets/img/Kate.png" alt="">
                  <p class="workers-name">Петухова Екатерина Вячеславовна </p>
                  <p class="workers-job">Руководитель отдела делопроизводства</p>
                </div>
              </div>
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
        <div id="carouselExamplee" class="carousel slide workers">
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="workers-container">
                <div class="worker">
                  <img src="../assets/img/Kirill.png" alt="">
                  <p class="workers-name">Скрипниченко Кирилл Сергеевич</p>
                  <p class="workers-job">Генеральный директор</p>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="workers-container">
                <div class="worker">
                  <img src="../assets/img/Miroslav.png" alt="">
                  <p class="workers-name">Мосин Мирослав Кириллович</p>
                  <p class="workers-job">Руководитель отдела кадров</p>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="workers-container">
                <div class="worker">
                  <img src="../assets/img/Vlad.png" alt="">
                  <p class="workers-name">Королев Владислав Александрович</p>
                  <p class="workers-job">Технический директор</p>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="workers-container">
                <div class="worker">
                  <img src="../assets/img/Egor.png" alt="">
                  <p class="workers-name">Булгаков Егор Витальевич</p>
                  <p class="workers-job">Заместитель технического директора</p>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="workers-container">
                <div class="worker">
                  <img src="../assets/img/Rose.png" alt="">
                  <p class="workers-name">Исаева Роза Исаевна </p>
                  <p class="workers-job">руководитель отдела маркетинга</p>
                </div>
              </div>
              </div>
              <div class="carousel-item">
                <div class="workers-container">
              <div class="worker">
                <img src="../assets/img/Kate.png" alt="">
                  <p class="workers-name">Петухова Екатерина Вячеславовна </p>
                  <p class="workers-job">Руководитель отдела делопроизводства</p>
              </div>
            </div>
          </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExamplee" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExamplee" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
  </div>
  <div class="nineth-block" id="licence">
    <div class="nineth-block-body">
      <div class="nineth-container-1">
        <p>БЛАГОДАРСТВЕННЫЕ ПИСЬМА</p>
        <p class="nineth-container-right-text">Наши усилия и профессионализм получили высокую оценку, что <br>
          подтверждается благодарственными письмами от наших клиентов</p>
      </div>
      <div class="nineth-container-2">
        <div class="nineth-container-list">
          <img src="../assets/img/shtern-list.png" alt="" style="cursor: pointer;" @click="openImgUrl = 'shtern-list.png'" data-bs-target="#openImageModal" data-bs-toggle="modal">
        </div>
        <div class="nineth-container-list">
          <img src="../assets/img/leda-list.png" alt="" style="cursor: pointer;" @click="openImgUrl = 'leda-list.png'" data-bs-target="#openImageModal" data-bs-toggle="modal">
        </div>
        <div class="nineth-container-list">
          <img src="../assets/img/pims-list.png" alt="" style="cursor: pointer;" @click="openImgUrl = 'pims-list.png'" data-bs-target="#openImageModal" data-bs-toggle="modal">
        </div>
        <div class="nineth-container-list">
          <img src="../assets/img/variant-list.png" alt="" style="cursor: pointer;" @click="openImgUrl = 'variant-list.png'" data-bs-target="#openImageModal" data-bs-toggle="modal">
        </div>
      </div>
      <div class="nineth-container-3">
        <p>НАШИ ЛИЦЕНЗИИ</p>
        <p class="nineth-container-right-text">Официальные документы, которые подтверждают нашу <br> компетентность
          и профессионализм</p>
      </div>
      <div class="nineth-container-4">
        <div id="carouselExampleFadeee" class="carousel slide carousel-fade">
          <div class="carousel-inner">
            <div class="carousel-item active nineth-list-container">
              <div class="nineth-container-list" >
                <img src="../assets/img/access-scan.png" alt="" style="cursor: pointer;" @click="openImgUrl = 'access-scan.png'" data-bs-target="#openImageModal" data-bs-toggle="modal" >
              </div>
              <div class="nineth-container-list">
                <img src="../assets/img/access-scan-2.png" alt="" style="cursor: pointer;" @click="openImgUrl = 'access-scan-2.png'" data-bs-target="#openImageModal" data-bs-toggle="modal">
              </div>
              <div class="nineth-container-list">
                <img src="../assets/img/access-scan-3.png" alt="" style="cursor: pointer;" @click="openImgUrl = 'access-scan-3.png'" data-bs-target="#openImageModal" data-bs-toggle="modal">
              </div>
              <div class="nineth-container-list">
                <img src="../assets/img/access-scan-4.png" alt="" style="cursor: pointer;" @click="openImgUrl = 'access-scan-4.png'" data-bs-target="#openImageModal" data-bs-toggle="modal">
              </div>
            </div>
            <div class="carousel-item nineth-list-container">
              <div class="nineth-container-list">
                <img src="../assets/img/access-scan-7.png" alt="" style="cursor: pointer;" @click="openImgUrl = 'access-scan-7.png'" data-bs-target="#openImageModal" data-bs-toggle="modal">
              </div>
              <div class="nineth-container-list">
                <img src="../assets/img/access-scan-6.png" alt="" style="cursor: pointer;" @click="openImgUrl = 'access-scan-6.png'" data-bs-target="#openImageModal" data-bs-toggle="modal">
              </div>
              <div class="nineth-container-list">
                <img src="../assets/img/access-scan-5.png" alt="" style="cursor: pointer;" @click="openImgUrl = 'access-scan-5.png'" data-bs-target="#openImageModal" data-bs-toggle="modal">
              </div>
            </div>
            <div class="carousel-item nineth-list-container">
              <div class="nineth-container-list">
                <img src="../assets/img/extract.png" alt="" style="cursor: pointer;" @click="openImgUrl = 'extract.png'" data-bs-target="#openImageModal" data-bs-toggle="modal">
              </div>
              <div class="nineth-container-list">
                <img src="../assets/img/extract-2.png" alt="" style="cursor: pointer;" @click="openImgUrl = 'extract-2.png'" data-bs-target="#openImageModal" data-bs-toggle="modal">
              </div>
              <div class="nineth-container-list">
                <img src="../assets/img/extract-3.png" alt="" style="cursor: pointer;" @click="openImgUrl = 'extract-3.png'" data-bs-target="#openImageModal" data-bs-toggle="modal">
              </div>
              <div class="nineth-container-list">
                <img src="../assets/img/extract-4.png" alt="" style="cursor: pointer;" @click="openImgUrl = 'extract-4.png'" data-bs-target="#openImageModal" data-bs-toggle="modal">
              </div>
            </div>
            <div class="carousel-item nineth-list-container">
              <div class="nineth-container-list">
                <img src="../assets/img/extract-5.png" alt="" style="cursor: pointer;" @click="openImgUrl = 'extract-5.png'" data-bs-target="#openImageModal" data-bs-toggle="modal">
              </div>
              <div class="nineth-container-list">
                <img src="../assets/img/extract-6.png" alt="" style="cursor: pointer;" @click="openImgUrl = 'extract-6.png'" data-bs-target="#openImageModal" data-bs-toggle="modal">
              </div>
              <div class="nineth-container-list">
                <img src="../assets/img/license.png" alt="" style="cursor: pointer;" @click="openImgUrl = 'license.png'" data-bs-target="#openImageModal" data-bs-toggle="modal">
              </div>
              <div class="nineth-container-list">
                <img src="../assets/img/license-2.png" alt="" style="cursor: pointer;" @click="openImgUrl = 'license-2.png'" data-bs-target="#openImageModal" data-bs-toggle="modal">
              </div>
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleFadeee"
            data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleFadeee"
            data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="tenth-block" id="questions">
    <p>ЧАСТО ЗАДАВАЕМЫЕ ВОПРОСЫ</p>
    <div class="FAQ" style="border-bottom: 1px solid;">
      <div class="question-1" @click="active_questions[0] = !active_questions[0]" :class="{'active': active_questions[0]}" data-bs-toggle="collapse" href="#connecting" role="button" aria-expanded="false"
          aria-controls="collapseExample">
        <p>Как происходит взаимодействие с клиентом на протяжении всего проекта?</p>
        <button class="btn btn-primary" v-if="active_questions[0]">
          <img src="../assets/img/faq-red-button.svg" alt="" class="tenth-block-visible"><img
            src="../assets/img/tenth-block-plus.png" alt="" class="tenth-block-hidden" >
        </button>
        <button class="btn btn-primary" v-else>
          <img src="../assets/img/faq-button.svg" alt="" class="tenth-block-visible"><img
            src="../assets/img/tenth-block-plus.png" alt="" class="tenth-block-hidden">
        </button>
      </div>
      <div class="collapse" id="connecting">
        <div class="card card-body">
          Взаимодействие с клиентом является ключевым элементом нашего подхода. Мы поддерживаем постоянный контакт с клиентом на всех этапах проекта, предоставляем регулярные отчеты о ходе работ и оперативно реагируем на все вопросы и пожелания.
        </div>
      </div>
      <div class="question-2" @click="active_questions[1] = !active_questions[1]" :class="{'active': active_questions[1]}" data-bs-toggle="collapse" href="#whobuy" role="button" aria-expanded="false"
          aria-controls="collapseExample">
        <p>Кто занимается закупкой и доставкой черновых материалов?</p>
        <button class="btn btn-primary" v-if="active_questions[1]">
          <img src="../assets/img/faq-red-button.svg" alt="" class="tenth-block-visible"><img
            src="../assets/img/tenth-block-plus.png" alt="" class="tenth-block-hidden" >
        </button>
        <button class="btn btn-primary" v-else>
          <img src="../assets/img/faq-button.svg" alt="" class="tenth-block-visible"><img
            src="../assets/img/tenth-block-plus.png" alt="" class="tenth-block-hidden">
        </button>
      </div>
      <div class="collapse" id="whobuy">
        <div class="card card-body">
          Закупку и доставку черновых материалов мы берем на себя. Это позволяет контролировать качество материалов и своевременность их доставки на объект. Мы сотрудничаем с проверенными поставщиками, что гарантирует высокое качество и соответствие стандартам.
        </div>
      </div>
      <div class="question-3" @click="active_questions[2] = !active_questions[2]" :class="{'active': active_questions[2]}" data-bs-toggle="collapse" href="#howpay" role="button" aria-expanded="false"
          aria-controls="collapseExample">
        <p>Как происходит оплата этапов работ?</p>
        <button class="btn btn-primary" v-if="active_questions[2]">
          <img src="../assets/img/faq-red-button.svg" alt="" class="tenth-block-visible"><img
            src="../assets/img/tenth-block-plus.png" alt="" class="tenth-block-hidden" >
        </button>
        <button class="btn btn-primary" v-else>
          <img src="../assets/img/faq-button.svg" alt="" class="tenth-block-visible"><img
            src="../assets/img/tenth-block-plus.png" alt="" class="tenth-block-hidden">
        </button>
      </div>
      <div class="collapse" id="howpay">
        <div class="card card-body">
          Оплата этапов работ происходит поэтапно согласно договору. Обычно оплата разбивается на несколько этапов: предоплата перед началом работ, промежуточные платежи по мере выполнения отдельных этапов и окончательный расчет после завершения проекта. Мы предоставляем прозрачные и подробные сметы, чтобы клиент всегда знал, за что он платит.
        </div>
      </div>
      <div class="question-4" @click="active_questions[3] = !active_questions[3]" :class="{'active': active_questions[3]}" data-bs-toggle="collapse" href="#howfast" role="button" aria-expanded="false"
          aria-controls="collapseExample">
        <div class="red-question">
          <p> <span class='red'>Как быстро вы можете приступить к работе после заключения договора? </span> </p>
        </div>
        <button class="btn btn-primary" v-if="active_questions[3]">
          <img src="../assets/img/faq-red-button.svg" alt="" class="tenth-block-visible"><img
            src="../assets/img/tenth-block-plus.png" alt="" class="tenth-block-hidden" >
        </button>
        <button class="btn btn-primary" v-else>
          <img src="../assets/img/faq-button.svg" alt="" class="tenth-block-visible"><img
            src="../assets/img/tenth-block-plus.png" alt="" class="tenth-block-hidden">
        </button>
      </div>
      <div class="collapse" id="howfast">
        <div class="card card-body">
          Мы стараемся приступить к работе как можно быстрее после заключения договора и согласования всех деталей проекта. В зависимости от сложности проекта и наличия материалов, мы можем начать работы в течение нескольких дней или недель. Точные сроки начала работ обсуждаются индивидуально с каждым клиентом.
        </div>
      </div>
      <div class="question-5" @click="active_questions[4] = !active_questions[4]" :class="{'active': active_questions[4]}" data-bs-toggle="collapse" href="#whatschedule" role="button"
          aria-expanded="false" aria-controls="collapseExample">
        <p>По какому графику будет осуществляться ремонт?</p>
        <button class="btn btn-primary" v-if="active_questions[4]">
          <img src="../assets/img/faq-red-button.svg" alt="" class="tenth-block-visible"><img
            src="../assets/img/tenth-block-plus.png" alt="" class="tenth-block-hidden" >
        </button>
        <button class="btn btn-primary" v-else>
          <img src="../assets/img/faq-button.svg" alt="" class="tenth-block-visible"><img
            src="../assets/img/tenth-block-plus.png" alt="" class="tenth-block-hidden">
        </button>
      </div>
      <div class="collapse" id="whatschedule">
        <div class="card card-body">
          График выполнения ремонтных работ составляется индивидуально для каждого проекта и согласовывается с клиентом. Мы можем работать по стандартному графику (понедельник - пятница, 9:00 - 18:00) или адаптировать график под нужды клиента, включая работу в ночное время и выходные дни. Это позволяет минимизировать неудобства и максимально эффективно использовать время.
        </div>
      </div>
      <div class="question-6" @click="active_questions[5] = !active_questions[5]" :class="{'active': active_questions[5]}" data-bs-toggle="collapse" href="#guarantees" role="button" aria-expanded="false"
          aria-controls="collapseExample" style="border-bottom: none;">
        <p>Какие гарантии вы предоставляете на выполненные работы?</p>
        <button class="btn btn-primary" v-if="active_questions[5]">
          <img src="../assets/img/faq-red-button.svg" alt="" class="tenth-block-visible"><img
            src="../assets/img/tenth-block-plus.png" alt="" class="tenth-block-hidden" >
        </button>
        <button class="btn btn-primary" v-else>
          <img src="../assets/img/faq-button.svg" alt="" class="tenth-block-visible"><img
            src="../assets/img/tenth-block-plus.png" alt="" class="tenth-block-hidden">
        </button>
      </div>
      <div class="collapse" id="guarantees">
        <div class="card card-body">
          Мы предоставляем гарантии на все выполненные работы и используемые материалы. Срок гарантии обычно составляет 3 года. В случае обнаружения недостатков или дефектов в гарантийный период, мы обязуемся устранить их за свой счет.
        </div>
      </div>
    </div>
  </div>
  </main>
  </div>
</template>

<style lang="scss"></style>

<script>
import GetEstimates from '@/components/GetEstimates.vue';
import CheckList from '@/components/CheckList.vue';
import PopUpMain from '@/components/PopUpMain.vue';
import PopUpCheckList from '@/components/PopUpCheckList.vue';
import PopUpQuestions from '@/components/PopUpQuestions.vue';
import OpenImageModal from '@/components/OpenImageModal.vue';
import MainMap from '@/components/MainMap.vue';

export default {
  components: {
    GetEstimates,
    CheckList,
    PopUpMain,
    PopUpCheckList,
    PopUpQuestions,
    OpenImageModal,
    MainMap
  },
  data() {
    return {
      imageFirst: require('../assets/img/first-project.png'),
      imageSecond: require('../assets/img/second-project.png'),
      imageThird: require('../assets/img/third-project.png'),
      imageFourth: require('../assets/img/fourth-project.png'),
      imageFifth: require('../assets/img/fifth-project.png'),
      imageSixth: require('../assets/img/sixth-project.png'),
      imageSeventh: require('../assets/img/seventh-project.png'),
      imageEighth: require('../assets/img/eighth-project.png'),
      imageNineth: require('../assets/img/nineth-project.png'),
      slides: [
        { img: 'сбер.png' },
        { img: 'псб.png' },
        { img: 'ашан.png' },
        { img: 'пимс.png' },
        { img: 'красныйкит.png' },
        { img: 'инвитро.png' },
        { img: 'легкийшаг.png' },
        { img: 'гентс.png' },
        { img: 'атитока.png' },
        { img: 'нексен.png' },
        { img: 'мега.png' },
        { img: '4хендс.png' },
        { img: 'крафтвей.png' },
        { img: 'синемапарк.png' },
        { img: 'авиапарк.png' },
        { img: 'оджи.png' },
        { img: 'селектел.png' },
        { img: 'мосгаз.png' },
        { img: 'содамода.png' },
        { img: 'штерн.png' },
        { img: 'сколково.png' },
        { img: 'цска.png' },
      ],
      slideIndex: 0,
      allowSwipe: true,
      isSwipe: false,
      transform: 0,
      posInit: 0,
      posX1: 0,
      posX2: 0,
      posY1: 0,
      posY2: 0,
      posFinal: 0,
      isScroll: false,
      transition: true,
      nextTrf: 0,
      prevTrf: 0,
      lastTrf: 0,
      posThreshold: 0,
      openImgUrl: 'access-scan-2.png',
      active_questions: [false, false, false, false, false, false]
    };
  },
  mounted() {
    this.initSlider();
  },
  methods: {
    goToProjects(category='') {
      this.$router.push(`/projects/${category}`).then(() => {
        window.scrollTo(0, 0);
      });
    },
    initSlider() {
      this.sliderTrack = this.$refs.sliderTrack;
      this.sliderList = this.$refs.sliderList;
      const slideElements = this.$refs.sliderTrack.children;
      this.slideWidth = slideElements[0].offsetWidth;
      this.lastTrf = -(slideElements.length - 14) * this.slideWidth;
      this.posThreshold = this.slideWidth * 0.35;
      this.sliderTrack.style.transform = 'translate3d(0px, 0px, 0px)';
      this.sliderList.classList.add('grab');
      this.sliderTrack.addEventListener('transitionend', () => {
        this.allowSwipe = true;
      });
      this.$refs.slider.addEventListener('touchstart', this.swipeStart);
      this.$refs.slider.addEventListener('mousedown', this.swipeStart);
    },
    mounted() {
      this.initSlider();
      this.slides.forEach((slide, index) => {
        const slideElement = this.$refs['slide' + index];
        const imgElement = slideElement.querySelector('img');
        imgElement.src = '../assets/img/' + slide.img;
      });
    },
    swipeStart(event) {
      let evt = event.touches ? event.touches[0] : event;
      if (this.allowSwipe) {
        this.transition = true;
        this.nextTrf = (this.slideIndex + 1) * -this.slideWidth;
        this.prevTrf = (this.slideIndex - 1) * -this.slideWidth;
        this.posInit = this.posX1 = evt.clientX; this.posY1 = evt.clientY;
        this.sliderTrack.style.transition = '';
        document.addEventListener('touchmove', this.swipeAction);
        document.addEventListener('mousemove', this.swipeAction);
        document.addEventListener('touchend', this.swipeEnd);
        document.addEventListener('mouseup', this.swipeEnd);
      }
    },
    swipeAction(event) {
      let evt = event.touches ? event.touches[0] : event;
      this.posX2 = evt.clientX;
      this.posY2 = evt.clientY;
      this.posFinal = this.posInit - this.posX2;
      this.transform = this.transform - this.posFinal;
      this.sliderTrack.style.transform = `translate3d(${this.transform}px, 0px, 0px)`;
      this.posInit = this.posX2; if (Math.abs(this.posFinal) > this.posThreshold) { this.isSwipe = true; }
    },
    swipeEnd() {
      this.isSwipe = false;
      this.transition = false;
      this.sliderTrack.style.transition = 'transform 0.5s ease-out';
      const swipeDistance = this.posX1 - this.posX2;
      const swipeDirection = swipeDistance > 0 ? 1 : -1;
      const newSlideIndex = this.slideIndex + Math.round(Math.abs(swipeDistance) / this.slideWidth) * swipeDirection;
      this.slideIndex = Math.max(0, Math.min(newSlideIndex, this.slides.length - 14));
      this.transform = this.slideIndex * -this.slideWidth;
      this.sliderTrack.style.transform = `translate3d(${this.transform}px, 0px, 0px)`;
      document.removeEventListener('touchmove', this.swipeAction);
      document.removeEventListener('mousemove', this.swipeAction);
      document.removeEventListener('touchend', this.swipeEnd);
      document.removeEventListener('mouseup', this.swipeEnd);
    },
    nextSlide() {
      if (this.slideIndex < this.slides.length - 14) {
        this.slideIndex += 2;
        this.transform = this.slideIndex * -this.slideWidth;
        this.sliderTrack.style.transform = `translate3d(${this.transform}px, 0px, 0px)`;
      }
    },
    prevSlide() {
      if (this.slideIndex > 1) {
        this.slideIndex -= 2;
        this.transform = this.slideIndex * -this.slideWidth;
        this.sliderTrack.style.transform = `translate3d(${this.transform}px, 0px, 0px)`;
      } else if (this.slideIndex === 1) {
        this.slideIndex = 0; this.transform = this.slideIndex * -this.slideWidth;
        this.sliderTrack.style.transform = `translate3d(${this.transform}px, 0px, 0px)`;
      }
    }
  },
} 
</script>
