<template>
  <div class="popup">
    <div class="container">
      <div class="img-block">
        <img src="../assets/img/popup-1-bg.png" alt="">
      </div>
      <div class="text-block">
        <div class="header-text">
          <p>Оставить заявку на ваши услуги</p>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><img src="../assets/img/back-button.png" alt=""></button>
        </div>
        <div class="bottom-text">
          <p>Заполните форму ниже, и мы свяжемся <br> с вами для уточнения деталей задачи</p>
        </div>
        <form class="input-container" @submit.prevent="sendForm">
            <input type="text" id="name" name="name" placeholder="Имя" required class="input-field" v-model="formName">
            <input type="email" id="email" name="email" placeholder="e-mail" required class="input-field" v-model="formMail">
            <input type="text" id="phone" name="phone" v-model="phoneNumber" class="mask-phone input-field" v-mask="'+# (###) ###-##-##'" placeholder="+7 (999) 999-99-99" required>
            <button data-bs-dismiss="modal" type="submit" class="submit-button">Оставить заявку</button>
          <p>Оставляя заявку, вы соглашаетесь с правилами <br> <a href="#">Политики конфиденциальности</a></p>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask';  
import axios from 'axios';
export default {
  directives: { mask },
       data() {
         return {
           phoneNumber: '',
           formMail: '',
           formName: ''
         };
       },
    methods: {
      sendTGMessage(message){
        const telegramBotToken = '6808300037:AAGvPRBQhx6cqn-QmTiNjTLyijxlQSmuFvo';
        const chatId = '-1002342697157';
        let data = {
            chat_id: chatId,
            text: message
        }
        axios.post(`https://api.telegram.org/bot${telegramBotToken}/sendMessage`, data)
      },
      sendForm(){
        this.sendTGMessage(`Новая заявка\n${this.formName}\n${this.formMail}\n${this.phoneNumber}`)
        alert('Заявка отправлена!')
      }
    }
}
</script>

<style lang="scss" scoped>
.popup {
  padding-top: 150px;
  position: fixed;
  height: 100%;
  width: 100%;
}

.container {
  overflow: hidden;
  max-width: 950px;
  max-height: 530px;
  display: flex;
  flex-direction: row;
  background: #000;

  .img-block {
    width: 69%;

    img {
      width: 100%;
    }
  }

  .text-block {
    padding: 15px !important;
    background: #000;
    color: #FFF;
    width: 100%;
    display: flex;
    flex-direction: column;

    .header-text {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-size: 32px;

      button {
        padding: 10px 10px 10px 10px !important;
        display: flex;
        align-items: start;
        width: fit-content;
        height: fit-content;
        justify-content: end;
        background: none;
        border: 0px;
        padding: 0;
        img {
          width: 20px;
          top: 0;
          height: auto;
        }
      }
    }

    .bottom-text {
      margin-bottom: 50px;

      p {
        font-size: 14px;
        line-height: 20px;
        color: #D6D6D6;
      }
    }
  }

  .input-container {
    display: flex;
    flex-direction: column;
    width: 300px;
    max-width: 450px;
    gap: 10px;

    #name {
      border: 1.5px solid #FFF;
      border-radius: 10px;
      background: #000;
      color: #FFF;
      padding-left: 20px;
      width: 530px;
      height: 60px;
    }

    #email {
      border: 1.5px solid #FFF;
      border-radius: 10px;
      background: #000;
      color: #FFF;
      padding-left: 20px;
      width: 530px;
      height: 60px;
    }

    #phone {
      border: 1.5px solid #FFF;
      border-radius: 10px;
      background: #000;
      color: #FFF;
      padding-left: 20px;
      width: 530px;
      height: 60px;
    }

    button {
      background: #E61E24;
      color: #FFF;
      border: 0px;
      height: 60px;
      border-radius: 10px;
      width: 530px;
    }

    p {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 520px;
      align-items: center;
      font-size: 12px;
      color: #D6D6D6 !important;
      line-height: 13px;
      margin: 0;

      a {
        color: #D6D6D6 !important;
      }
    }
  }
}

@media (max-width: 1100px) {
  .container {
    max-width: 650px;
    max-height: 460px;

    .img-block {
      img {
        width: 320px;
      }
    }

    .text-block {
      .header-text {
        font-size: 24px;
      }

      .bottom-text {
        margin-bottom: 20px;

        p {
          font-size: 12px;
          line-height: 15px;
        }
      }
    }

    .input-container {
      #name {
        width: 290px;
        height: 50px;
      }

      #email {
        width: 290px;
        height: 50px;
      }

      #phone {
        width: 290px;
        height: 50px;
      }

      button {
        width: 290px;
        height: 50px;
      }

      p {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 320px;
        align-items: center;
        font-size: 12px;
        color: #D6D6D6 !important;
        line-height: 13px;
        margin: 0;

        a {
          color: #D6D6D6 !important;
        }
      }
    }
  }
}

@media (max-width: 690px) {
  .container {
    max-width: 300px;
    max-height: 700px;
    display: flex;
    flex-direction: column;

    .img-block {
      height: 200px;
      display: flex;
      margin-left: 25px;
      justify-content: center;
      align-items: center;

      img {
        padding-left: 50px;
        width: 360px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: auto;
      }
    }

    .text-block {
      padding: 15px !important;
      background: #000;
      color: #FFF;
      width: 100%;
      display: flex;
      flex-direction: column;

      .header-text {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 32px;
        height: 80px !important;

        p {
          font-size: 24px;
        }

        button {
          transform: translateY(-200px);
          height: 40px;

          img {
            width: 20px;
            height: auto;
          }
        }
      }

      .bottom-text {
        margin-bottom: 10px;

        p {
          line-height: 15px;
        }
      }
    }

    .input-container {
      display: flex;
      flex-direction: column;
      width: 300px;
      max-width: 450px;
      gap: 10px;

      #name {
        width: 270px;
        height: 60px;
      }

      #email {
        width: 270px;
        height: 60px;
      }

      #phone {
        width: 270px;
        height: 60px;
      }

      button {
        background: #E61E24;
        color: #FFF;
        border: 0px;
        width: 270px;
        height: 60px;
      }

      p {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 270px;
        align-items: center;
        font-size: 10px;
        color: #D6D6D6 !important;
        line-height: 13px;
        margin: 0;

        a {
          color: #D6D6D6 !important;
        }
      }
    }
  }

}
</style>