<template>
  <div class="modal fade" id="PopUpQuestions" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <PopUpQuestions />
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="PopUpMain" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <PopUpMain />
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="office-head">
      <div class="path-to-office">
        <p><a href="/#main">Главная / </a> <a :href="'/projects/'+$props.category">{{ $props.category }} / </a> {{ $props.title }}</p>
        <p class="fz">{{ $props.title }}</p>
      </div>
      <div class="office-info">
        <div class="office-location">
          <p style="color: #979797; font-size: 14px; margin-bottom: 0px;">Расположение</p>
          <p>{{ item.address }}</p>
        </div>
        <div class="office-square">
          <p style="color: #979797; font-size: 14px; margin-bottom: 0px;">Площадь</p>
          <p>{{ item.size }} м2</p>
        </div>
        <div class="office-timing">
          <p style="color: #979797; font-size: 14px; margin-bottom: 0px;">Срок реализации</p>
          <p>{{ item.time }}</p>
        </div>
      </div>
    </div>
    <div class="office-img">
      <img :src="`https://nikxr.ru/api/portfolio/${item.gallery_path.replaceAll(' ', '%20')}/${item.main_image.replaceAll(' ', '%20')}`" alt="">
    </div>
    <div class="about-project">
      <p class="fz"> О ПРОЕКТЕ </p>
      <div class="project-info">
        <div class="project-info-location">
          <p style="color: #979797;">Расположение</p>
          <p>{{ item.address }}</p>
        </div>
        <div class="project-info-square">
          <p style="color: #979797;">Площадь</p>
          <p>{{ item.size }} м2</p>
        </div>
        <div class="project-info-timing">
          <p style="color: #979797;">Срок реализации</p>
          <p>{{ item.time }}</p>
        </div>
        <div class="project-discryption">
          <p>{{ item.description }}</p>
        </div>
      </div>
    </div>
    <div class="project-gallery">
      <p class="fz">ГАЛЕРЕЯ ПРОЕКТА</p>
      <div class="gallery-buttons">
        <button :class="{'active-button': active_gallery == 'ДО'}" @click="active_gallery = 'ДО'">До</button>
        <button :class="{'active-button': active_gallery == 'В ПРОЦЕССЕ'}" @click="active_gallery = 'В ПРОЦЕССЕ'">В процессе</button>
        <button :class="{'active-button': active_gallery == 'ПОСЛЕ'}" @click="active_gallery = 'ПОСЛЕ'">После</button>
      </div>
      <div class="gallery-images px-3">
        <div class="row">
          <div class="col-lg-3 col-md-4 col-6 p-0" v-for="(item_img, index_img) in item[active_gallery]" :key="index_img"><img :src="`https://nikxr.ru/api/portfolio/${item.gallery_path}/${active_gallery.replaceAll(' ', '%20')}/${item_img.replaceAll(' ', '%20')}`"  alt="" ></div>
        </div>
        <!--<div class="images-group">
          <div class="img">
            <img src="../assets/img/gallery-1.png" alt="">
          </div>
          <div class="img">
            <img src="../assets/img/gallery-2.png" alt="">
          </div>
        </div>
        <div class="images-group">
          <div class="img">
            <img src="../assets/img/gallery-3.png" alt="">
          </div>
          <div class="img">
            <img src="../assets/img/gallery-4.png" alt="">
          </div>
        </div>
        <div class="images-group">
          <div class="img">
            <img src="../assets/img/gallery-5.png" alt="">
          </div>
          <div class="img">
            <img src="../assets/img/gallery-6.png" alt="">
          </div>
        </div>-->
      </div>
    </div>
    <!--<div id="carouselExample" class="carousel slide">
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img src="../assets/img/gallery-1.png" class="d-block w-100" alt="...">
        </div>
        <div class="carousel-item">
          <img src="../assets/img/gallery-2.png" class="d-block w-100" alt="...">
        </div>
        <div class="carousel-item">
          <img src="../assets/img/gallery-3.png" class="d-block w-100" alt="...">
        </div>
        <div class="carousel-item">
          <img src="../assets/img/gallery-4.png" class="d-block w-100" alt="...">
        </div>
        <div class="carousel-item">
          <img src="../assets/img/gallery-5.png" class="d-block w-100" alt="...">
        </div>
        <div class="carousel-item">
          <img src="../assets/img/gallery-6.png" class="d-block w-100" alt="...">
        </div>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>-->
    <div class="another-projects">
      <p class="fz">ДРУГИЕ НАШИ ПРОЕКТЫ</p>
      <div class="download-another">
        <p>Вы можете ознакомиться с нашими реализованными проектами, <br> чтобы убедиться в качестве наших
          выполненных работ.</p>
        <a href="https://nikxr.ru/docs/СК%20Элит%20Презентация%202024.pdf" download style="color: #E61E24; text-align: right;">Скачать презентацию работ</a>
      </div>
    </div>
    <!--<div class="two-projects">
      <div @click="goToDetails" class="project-main">
        <div class="project" :style="{ backgroundImage: `url(${imageFifth})` }">
          <div class="project-opacity">
            <div class="project-info">
              <p>Проект № 5</p>
              <p>Россия, г. Москва</p>
            </div>
            <div class="project-more-detailed">
              <a href="/projects/details">Подробнее о проекте</a>
            </div>
          </div>

        </div>
      </div>
      <div @click="goToDetails" class="project-main">
        <div class="project" :style="{ backgroundImage: `url(${imageFifth})` }">
          <div class="project-opacity">
            <div class="project-info">
              <p>Проект № 5</p>
              <p>Россия, г. Москва</p>
            </div>
            <div class="project-more-detailed">
              <a href="/projects/details">Подробнее о проекте</a>
            </div>
          </div>

        </div>
      </div>
    </div>-->
    <GetEstimates />
  </div>
</template>

<script>
import GetEstimates from '@/components/GetEstimates.vue';
import PopUpQuestions from '@/components/PopUpQuestions.vue';
import PopUpMain from '@/components/PopUpMain.vue'
import axios from 'axios'
export default {
  props: {
    title: String,
    category: String
  },
  components: {
    GetEstimates,
    PopUpMain,
    PopUpQuestions
  },
  data() {
    return {
      imageFifth: require('../assets/img/gallery-2.png'),
      item: {},
      active_gallery: 'ПОСЛЕ'
    }
  },
  methods: {
    goToDetails() {
      this.$router.push('/projects/details').then(() => {
        window.scrollTo(0, 0);
      });
    },

    loadData(){
      axios.post('https://nikxr.ru/api/get_item.php', {category: this.$props.category, title: this.$props.title}).then((response)=>{
        console.log(response.data)
        this.item = response.data
      })
    }
  },
  created(){
    this.loadData()
  }
};

</script>

<style lang="scss">
@import url(../assets/styles/ProjectDetails.scss);
</style>
