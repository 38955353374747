<template>
  <div class="modal fade" id="PopUpMain" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <PopUpMain/>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="projects">
      <div class="realised-projects-text">
        <div class="text-top">
          <p class="project-text-1"> <a href="/#main">Главная / </a>{{ $props.category }}</p>
          <p class="project-text-2">РЕАЛИЗОВАННЫЕ ПРОЕКТЫ</p>
        </div>
        <div class="text-bottom">
          <p class="project-text-3">Вы можете ознакомиться с нашими реализованными проектами, <br> чтобы убедиться в
            качестве наших выполненных работ.</p>
            <a href="https://nikxr.ru/docs/СК%20Элит%20Презентация%202024.pdf" download style="color: #E61E24; text-align: right;">Скачать презентацию работ</a>
        </div>
      </div>
      <div class="realised-projects">
        <button @click="goToDetails(item.title)" class="project-main" v-for="(item, index) in items" :key="index">
            <div class="project" :style="{backgroundImage: `url(https://nikxr.ru/api/portfolio/${item.gallery_path}/${item.main_image.replaceAll(' ', '%20')})` }">
            <div class="project-opacity">
              <div class="project-info">
                <p>{{ item.title }}</p>
                <p>{{ item.address }}</p>
              </div>
              <div class="project-more-detailed">
                <a :href="`/projects/${$props.category}/${item.title}`">Подробнее о проекте</a>
              </div>
            </div>
          </div>
        </button>
        <!--<button @click="goToDetails" class="project-main">
          <div class="project" :style="{ backgroundImage: `url(${imageSecond})` }">
            <div class="project-opacity">
              <div class="project-info">
                <p>Проект № 2</p>
                <p>Россия, г. Москва</p>
              </div>
              <div class="project-more-detailed">
                <a href="/projects/details">Подробнее о проекте</a>
              </div>
            </div>
          </div>
        </button>
        <button @click="goToDetails" class="project-main">
          <div class="project" :style="{ backgroundImage: `url(${imageThird})` }">
            <div class="project-opacity">
              <div class="project-info">
                <p>Проект № 3</p>
                <p>Россия, г. Москва</p>
              </div>
              <div class="project-more-detailed">
                <a href="/projects/details">Подробнее о проекте</a>
              </div>
            </div>
          </div>
        </button>
        <button @click="goToDetails" class="project-main">
          <div class="project" :style="{ backgroundImage: `url(${imageFourth})` }">
            <div class="project-opacity">
              <div class="project-info">
                <p>Проект № 4</p>
                <p>Россия, г. Москва</p>
              </div>
              <div class="project-more-detailed">
                <a href="/projects/details">Подробнее о проекте</a>
              </div>
            </div>
          </div>
        </button>
        <button @click="goToDetails" class="project-main">
          <div class="project" :style="{ backgroundImage: `url(${imageFifth})` }">
            <div class="project-opacity">
              <div class="project-info">
                <p>Проект № 5</p>
                <p>Россия, г. Москва</p>
              </div>
              <div class="project-more-detailed">
                <a href="/projects/details">Подробнее о проекте</a>
              </div>
            </div>

          </div>
        </button>
        <button @click="goToDetails" class="project-main">
          <div class="project" :style="{ backgroundImage: `url(${imageSixth})` }">
            <div class="project-opacity">
              <div class="project-info">
                <p>Проект № 6</p>
                <p>Россия, г. Москва</p>
              </div>
              <div class="project-more-detailed">
                <a href="/projects/details">Подробнее о проекте</a>
              </div>
            </div>
          </div>
        </button>-->
      </div>
    </div>
  </div>
</template>

<script>
import PopUpMain from '@/components/PopUpMain.vue'
import axios from 'axios'
export default {
  props: {
    category: String
  },
  components: {
    PopUpMain,
  },
  data () {
    return {
      imageFirst: require('../assets/img/gallery-4.png'),
      imageSecond: require('../assets/img/gallery-5.png'),
      imageThird: require('../assets/img/gallery-1.png'),
      imageFourth: require('../assets/img/gallery-3.png'),
      imageFifth: require('../assets/img/gallery-2.png'),
      imageSixth: require('../assets/img/gallery-6.png'),
      items: []
    }
  },
  methods: {
    goToDetails(title) {
      this.$router.push(`/projects/${this.$props.category}/${title}`).then(() => {
        window.scrollTo(0, 0);
      });
    },

    loadData(){
      axios.post('https://nikxr.ru/api/get_items.php', {category: this.$props.category}).then((response)=>{
        console.log(response.data)
        this.items = response.data
      })
    }
  },
  created(){
    this.loadData()
  }
};
</script>
<style lang="scss">
@import url(../assets/styles/RealisedProject.scss)
</style>