<template>
<a v-if="moveBack == 'Россия'" @click="showMap = 'Россия'; moveBack=''">&larr; Карта России</a>
<a v-if="moveBack == 'Москва'" @click="showMap = 'Москва'; moveBack = 'Россия'">&larr; Карта Москвы</a>
<transition name="slide-fade">
<figure class='backing' v-if="showMap == 'Россия'">
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 4886 4263" preserveAspectRatio="xMinYMin meet" >
        <image width="4886" height="4263" xlink:href="https://nikxr.ru/map/main_map_2.png"></image>
        
        <a @click="showMap = 'Москва'; moveBack = 'Россия'"><polygon points="1662,1845,1681,1829,1694,1803,1697,1787,1723,1790,1742,1758,1761,1758,1777,1774,1793,1800,1829,1800,1845,1819,1883,1822,1925,1835,1931,1857,1928,1905,1912,1941,1899,1976,1921,2008,1934,2030,1931,2072,1912,2078,1889,2088,1864,2094,1841,2097,1813,2091,1800,2085,1800,2107,1781,2107,1758,2094,1774,2072,1761,2040,1758,2014,1755,1989,1729,1966,1704,1957,1704,1921,1672,1909,1653,1883"/></a>
        <a @click="showMap = 'Саратов'; moveBack = 'Россия'"><polygon points="1950,2923,1954,2874,1946,2855,1924,2839,1908,2811,1886,2786,1870,2752,1878,2711,1881,2690,1850,2664,1807,2645,1777,2642,1750,2624,1753,2605,1769,2584,1761,2559,1793,2546,1817,2530,1849,2512,1895,2519,1894,2546,1910,2555,1950,2567,1964,2591,1991,2621,2050,2631,2087,2634,2110,2640,2145,2655,2174,2661,2191,2667,2193,2695,2199,2736,2226,2752,2254,2770,2246,2816,2263,2856,2263,2887,2255,2920,2255,2941,2196,2949,2174,2979,2134,2951,2103,2976,2065,2952,2047,2995,2018,3015,1972,3000,1959,2955"/></a>
        <a @click="showMap = 'Воронеж'; moveBack = 'Россия'"><polygon points="1434,2642,1474,2655,1561,2648,1599,2632,1615,2613,1622,2587,1622,2563,1642,2555,1673,2555,1679,2570,1711,2587,1727,2602,1750,2610,1762,2597,1770,2573,1753,2557,1740,2525,1706,2491,1679,2461,1687,2418,1583,2327,1540,2330,1529,2341,1521,2395,1500,2416,1479,2445,1478,2479,1452,2522,1433,2544,1441,2576,1431,2602,1431,2615"/></a>
        <a @click="showMap = 'Самара'; moveBack = 'Россия'"><polygon points="2273,2893,2290,2879,2308,2879,2332,2883,2348,2877,2364,2861,2374,2843,2380,2832,2418,2832,2431,2827,2444,2810,2452,2800,2465,2795,2481,2791,2484,2779,2466,2778,2457,2768,2471,2752,2497,2733,2518,2715,2537,2688,2532,2663,2522,2650,2522,2634,2500,2637,2481,2637,2466,2643,2446,2637,2430,2635,2394,2626,2369,2624,2343,2631,2300,2608,2273,2600,2247,2611,2215,2618,2210,2629,2207,2648,2193,2664,2193,2688,2191,2722,2198,2747,2228,2755,2247,2768,2244,2803,2255,2823,2263,2847,2265,2875"/></a>
        
    </svg>
</figure>
</transition>
<transition name="slide-fade">
<figure class='backing' v-if="showMap == 'Саратов'">
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 2889 3502" preserveAspectRatio="xMinYMin meet" >
        <image width="2889" height="3502" xlink:href="https://nikxr.ru/map/Саратов.png"></image>

        <a href="projects/Ремонт%20кинотеатров/СИНЕМАПАРК%20Триумф" target="_blank"><image href="../assets/img/map_marker.png" height="200" :x="1541-50" :y="1923-135" width="100"></image></a>
    </svg>
</figure>
</transition>
<transition name="slide-fade">
<figure class='backing' v-if="showMap == 'Самара'">
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 3482 4014" preserveAspectRatio="xMinYMin meet" >
        <image width="3482" height="4014" xlink:href="https://nikxr.ru/map/Самара.png"></image>
        
        <a href="projects/Ремонт%20кинотеатров/СИНЕМАПАРК%20Парк%20Хаус" target="_blank"><image href="../assets/img/map_marker.png" height="200" :x="2310-50" :y="2634-135" width="100"></image></a>

    </svg>
</figure>
</transition>
<transition name="slide-fade">
<figure class='backing' v-if="showMap == 'Воронеж'">
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 2850 3596" preserveAspectRatio="xMinYMin meet" >
        <image width="2850" height="3596" xlink:href="https://nikxr.ru/map/Воронеж.png"></image>

        <a href="projects/Ремонт%20магазинов%20и%20шоурумов/Магазин%20одежды%20Oodgi" target="_blank"><image href="../assets/img/map_marker.png" height="200" x="1066" y="1258" width="100"></image></a>
        
    </svg>
</figure>
</transition>
<transition name="slide-fade">
<figure class='backing' v-if="showMap == 'Питер'">
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 3755 3553" preserveAspectRatio="xMinYMin meet" >
        <image width="3755" height="3553" xlink:href="https://nikxr.ru/map/Питер.png"></image>
        
    </svg>
</figure>
</transition>
<transition name="slide-fade">
<figure class='backing' v-if="showMap == 'Москва'">
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 4205 5223" preserveAspectRatio="xMinYMin meet" >
        <image width="4205" height="5223" xlink:href="https://nikxr.ru/map/Москва.png"></image>

        <a @click="showMap = 'Север'; moveBack = 'Москва'"><polygon points="1652,328,1585,341,1591,424,1473,456,1575,814,1748,971,1783,1233,1780,1432,1818,1502,1777,1589,1847,1790,1818,1806,1850,1960,1940,1969,1940,2021,1991,2037,2007,2065,2026,1976,2129,1963,2129,1902,2209,1950,2340,1659,2346,1515,2378,1486,2442,1397,2436,1358,2436,1323,2487,1345,2481,1413,2609,1384,2612,1265,2513,1259,2615,1144,2689,1112,2548,961,2471,933,2333,888,1719,712,1735,392,1690,369"/></a>
        <a @click="showMap = 'Северо-запад'; moveBack = 'Москва'"><polygon points="1041,1050,1263,1015,1314,999,1561,813,1682,928,1746,989,1761,1167,1780,1424,1809,1506,1775,1573,1860,1967,1830,2120,1622,2157,1521,2264,1466,2250,1297,1698,1329,1624,1233,1615,1241,1568,1078,1162,1030,1111"/></a>
        <a @click="showMap = 'Северо-запад 2'; moveBack = 'Москва'"><polygon points="1025,1128,1105,1169,1239,1605,1319,1624,1303,1774,1405,2091,1479,2251,1450,2309,1261,2213,1213,2379,1089,2485,1005,2411,1156,2261,1092,2107,855,2136,647,2171,615,2049,439,2248,301,2245,301,2142,433,2017,481,1889,564,1854,605,1925,676,1752,493,1723,429,1525,407,1345,513,1195,682,1182,701,1275,756,1237,660,1089,599,971,596,853,589,712,570,625,484,664,410,440,397,533,500,309,653,273,762,296,906,315,929,392,881,475,874,584,833,664,817,763,807,853,849,897,861,1016,941,1073"/></a>
        <a @click="showMap = 'Запад'; moveBack = 'Москва'"><polygon points="612,2166,828,2157,912,2145,1017,2147,1073,2118,1157,2265,1082,2351,986,2394,1080,2495,1171,2452,1233,2358,1248,2236,1286,2217,1440,2313,1483,2442,1605,2447,1685,2377,1737,2373,1754,2469,1641,2589,1591,2651,1596,2740,1653,2800,1665,2817,1428,3095,1490,3172,1123,3498,974,3289,893,3297,888,3407,854,3597,624,3645,422,3534,381,3479,453,3340,475,3232,607,3210,732,3244,809,3289,878,3229,818,3181,840,3119,888,3083,785,2874,636,2481,612,2418,624,2262,593,2241,609,2197"/></a>
        <a @click="showMap = 'Юго-запад'; moveBack = 'Москва'"><polygon points="1673,2829,1713,2797,1819,2783,1845,2826,1953,2994,1953,3052,2011,3076,1965,3177,1973,3239,1992,3277,1961,3460,1817,3450,1831,3503,1805,3570,1869,3582,1848,3635,1797,3702,1809,3731,1773,3817,1812,3851,1817,3947,1788,4081,1884,4134,1922,4221,1941,4271,1869,4348,1833,4677,1932,4799,1891,4921,1754,4902,1764,4770,1692,4789,1675,4869,1553,4900,1493,4890,1565,4749,1250,4650,1195,4530,1620,4341,1646,4247,1581,4197,1617,4038,1303,3817,1133,3525,1289,3376,1464,3215,1490,3162,1433,3097,1629,2879"/></a>
        <a @click="showMap = 'Юг'; moveBack = 'Москва'"><polygon points="1860,2778,1903,2673,2121,2673,2186,2658,2193,2615,2275,2625,2345,2879,2556,2987,2561,3076,2373,3198,2352,3357,2546,3494,2671,3482,2810,3465,2971,3410,2990,3554,3048,3652,2695,3988,2460,4137,2282,4187,1944,4141,1956,4206,1908,4189,1805,4084,1821,4034,1831,3926,1819,3861,1781,3822,1809,3731,1833,3714,1802,3676,1855,3599,1805,3558,1841,3510,1814,3443,1956,3472,1982,3318,2001,3270,1970,3244,1992,3222,1975,3153,2018,3066,1961,3038,1927,2944,1850,2821" /></a>
        <a @click="showMap = 'Юго-восток'; moveBack = 'Москва'"><polygon points="2289,2634,2342,2884,2414,2922,2563,3004,2570,3083,2424,3167,2359,3244,2352,3354,2503,3493,2649,3486,2810,3481,2966,3414,2995,3573,3041,3642,3240,3438,3293,3179,3271,3057,3415,3109,3425,2963,3429,2913,3386,2857,2865,2524,2750,2457,2618,2368,2621,2236,2580,2142,2508,2209,2453,2214,2414,2291,2361,2317,2345,2370,2472,2447,2508,2541,2472,2584,2424,2572,2397,2610,2333,2627,2309,2584,2287,2603" /></a>
        <a @click="showMap = 'Восток'; moveBack = 'Москва'"><polygon points="2693,1115,2611,1158,2539,1242,2520,1259,2599,1281,2621,1367,2479,1405,2462,1329,2429,1333,2441,1369,2361,1497,2333,1667,2220,1919,2222,1993,2388,2049,2551,2094,2618,2255,2611,2385,2762,2473,2882,2529,3113,2692,3355,2824,3463,2925,3590,2869,3729,2831,3811,2773,3893,2689,3813,2593,3705,2562,3533,2651,3439,2617,3449,2548,3523,2495,3593,2452,3602,2361,3475,2363,3317,2404,3324,1669,2731,1115" /></a>
        <a @click="showMap = 'Центр'; moveBack = 'Москва'"><polygon points="1543,2256,1636,2160,1807,2135,1849,2098,1865,2005,1876,1970,1932,1971,1934,2016,1969,2039,2006,2058,2030,2005,2036,1967,2111,1960,2134,1927,2183,1941,2241,2000,2412,2061,2524,2067,2562,2114,2489,2210,2444,2213,2404,2290,2338,2311,2348,2343,2338,2383,2497,2483,2497,2557,2468,2584,2423,2563,2399,2602,2340,2623,2287,2578,2282,2626,2202,2610,2190,2648,2012,2690,1982,2687,1900,2661,1846,2781,1791,2760,1674,2794,1612,2722,1617,2632,1713,2549,1783,2451,1756,2370,1705,2370,1641,2410,1586,2440,1516,2448,1479,2415,1465,2362,1449,2295,1518,2264" /></a>
    </svg>
</figure>
</transition>
<transition name="slide-fade">
<figure class='backing' v-if="showMap == 'Север'">
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 2178 2793" preserveAspectRatio="xMinYMin meet" >
        <image width="2178" height="2793" xlink:href="https://nikxr.ru/map/Север@2x.png"></image>
        
    </svg>
</figure>
</transition>
<transition name="slide-fade">
<figure class='backing' v-if="showMap == 'Северо-запад'">
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1810 2565" preserveAspectRatio="xMinYMin meet" >
        <image width="1810" height="2565" xlink:href="https://nikxr.ru/map/Сев-зап@2x.png"></image>
        
        <a href="projects/Ремонт%20салонов%20красоты%20и%20СПА/4HANDS%20Ильменский%20пр." target="_blank"><image href="../assets/img/map_marker.png" height="200" :x="1119-50" :y="1015-135" width="100"></image></a>
        <a href="projects/Ремонт%20салонов%20красоты%20и%20СПА/4HANDS%20Лобненская%20ул." target="_blank"><image href="../assets/img/map_marker.png" height="200" x="973" y="582" width="100"></image></a>
    </svg>
</figure>
</transition>
<transition name="slide-fade">
<figure class='backing' v-if="showMap == 'Северо-запад 2'">
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 2114 3419" preserveAspectRatio="xMinYMin meet" >
        <image width="2114" height="3419" xlink:href="https://nikxr.ru/map/Сев-запад.png"></image>

        <a href="projects/Ремонт%20магазинов%20и%20шоурумов/Магазин%20текстиля%20Marya%20G" target="_blank"><image href="../assets/img/map_marker.png" height="200" x="1100" y="2267" width="100"></image></a>
        <a href="projects/Ремонт%20салонов%20красоты%20и%20СПА/4HANDS%20Волоколамское%20ш." target="_blank"><image href="../assets/img/map_marker.png" height="200" x="1038" y="1940" width="100"></image></a>
        
    </svg>
</figure>
</transition>
<transition name="slide-fade">
<figure class='backing' v-if="showMap == 'Запад'">
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 2420 2589" preserveAspectRatio="xMinYMin meet" >
        <image width="2420" height="2589" xlink:href="https://nikxr.ru/map/Запад@2x.png"></image>

        <a href="projects/Ремонт%20магазинов%20и%20шоурумов/Белорусские%20кухни%20ЗОВ" target="_blank"><image href="../assets/img/map_marker.png" height="200" x="958" y="750" width="100"></image></a>
        
    </svg>
</figure>
</transition>
<transition name="slide-fade">
<figure class='backing' v-if="showMap == 'Юго-запад'">
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1953 3580" preserveAspectRatio="xMinYMin meet" >
        <image width="1953" height="3580" xlink:href="https://nikxr.ru/map/Юг-запад@2x.png"></image>
        
    </svg>
</figure>
</transition>
<transition name="slide-fade">
<figure class='backing' v-if="showMap == 'Юг'">
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 2324 2760" preserveAspectRatio="xMinYMin meet" >
        <image width="2324" height="2760" xlink:href="https://nikxr.ru/map/Юг@2x.png"></image>
        
    </svg>
</figure>
</transition>
<transition name="slide-fade">
<figure class='backing' v-if="showMap == 'Юго-восток'">
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 2162 2760" preserveAspectRatio="xMinYMin meet" >
        <image width="2162" height="2565" xlink:href="https://nikxr.ru/map/Юг-вос@2x.png"></image>
        
    </svg>
</figure>
</transition>
<transition name="slide-fade">
<figure class='backing' v-if="showMap == 'Восток'">
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1943 3035" preserveAspectRatio="xMinYMin meet" >
        <image width="1943" height="3035" xlink:href="https://nikxr.ru/map/Восток@2x.png"></image>
        
    </svg>
</figure>
</transition>
<transition name="slide-fade">
<figure class='backing' v-if="showMap == 'Центр'">
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 2044 1716" preserveAspectRatio="xMinYMin meet" >
        <image width="2044" height="1716" xlink:href="https://nikxr.ru/map/центр@2x.png"></image>
        
        <a href="projects/Ремонт%20ресторанов%20и%20баров/Кафе%20PIMS" target="_blank"><image href="../assets/img/map_marker.png" height="200" x="1115" y="658" width="100"></image></a>
        <a href="/projects/Ремонт%20магазинов%20и%20шоурумов/Магазин%20элитных%20брендовых%20подарков%20Ручка.ру" target="_blank"><image href="../assets/img/map_marker.png" height="200" x="754" y="660" width="100"></image></a>

    </svg>
</figure>
</transition>
</template>

<script>
export default{
    data(){
        return{
            showMap: 'Россия',
            moveBack: ''
        }
    }
}
/* <a xlink:href="game1.html"><circle cx="243" cy="133" r="79" fill="#fff" opacity="0.15" /></a>
  <a xlink:href="game2.html"><rect x="870" y="147" width="680" height="33" fill="#fff" opacity="0.25"/></a>
  <a xlink:href="game3.html"><circle cx="889" cy="379" r="80" fill="#fff" opacity="0.1"/></a>
  <a xlink:href="https://code.org/educate/csp"><circle cx="770" cy="671" r="73" fill="#fff" opacity="0.2"/></a> */
</script>

<style lang="scss" scoped>
.backing {
    vertical-align: middle;
    margin: 0;
    // overflow: hidden;
    background: #000;
}
.backing svg { 
    display: inline-block;
    position: relative;
    top: 0; left: 0;
}

polygon{
    fill: #fff;
    opacity: 0;
}

a{
    cursor: pointer;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s ease-in;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

</style>