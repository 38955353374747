<template>
    <div class="container">
        <div class="modal fade" data-bs-backdrop="static" id="openImageModal" tabindex="-1" aria-labelledby="openImageModal" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        <img :src="modalImg" alt="">
    </div>
  </div>
</div>
    </div>
    
</template>

<script>
export default{
    props: {
        imgUrl: String
    },
    data(){
        return{
            modalImg: require(`@/assets/img/${this.imgUrl}`)
        }
    },
    watch: {
    imgUrl(val) {
       this.modalImg = require(`@/assets/img/${val}`)
    }
  },
}
</script>

<style lang="scss" scoped>
#openImageModal{
    .modal-content{
        max-height: 100vh;
        position: relative;
        width: auto;
        height: auto;
        img{
            max-height: 99vh;
            width: 100%;
            max-width: 100vw;
            object-fit: contain;
        }

        button{
            position: absolute;
            top: 30px;
            right: 30px;
        }
    }
}
</style>