<template>
    <div class="container" style="padding-top: 150px !important; max-width: 500px; width: 100%;">
        <form action="https://nikxr.ru/api/create_item.php" method="POST">
            <h1>Создать объект</h1>

            <label for="categoryInput" class="form-label mt-3">Категория</label>
            <select id="categoryInput" class="form-select" name="category" required>
                <option value="">Выберите категорию</option>
                <option>Ремонт офисов и коммерческой недвижимости</option>
                <option>Ремонт магазинов и шоурумов</option>
                <option>Ремонт ресторанов и баров</option>
                <option>Ремонт кинотеатров</option>
                <option>Ремонт бизнес-центров</option>
                <option>Ремонт салонов красоты и СПА</option>
                <option>Ремонт банков</option>
                <option>Ремонт гостиниц</option>
                <option>Ремонт производственных помещений</option>
            </select>

            <label for="titleInput" class="form-label mt-3">Название</label>
            <input type="text" class="form-control" name="title" placeholder="Введите название" id="titleInput" required>

            <label for="addressInput" class="form-label mt-3">Адрес</label>
            <input type="text" class="form-control" name="address" placeholder="Введите адрес" id="addressInput" required>

            <label for="sizeInput" class="form-label mt-3">Площадь</label>
            <input type="number" class="form-control" name="size" placeholder="Введите площадь" id="sizeInput" required>

            <label for="timeInput" class="form-label mt-3">Сроки реализации</label>
            <input type="text" class="form-control" name="time" placeholder="Введите сроки реализации" id="timeInput" required>

            <label for="imageInput" class="form-label mt-3">Главное изображение</label>
            <input type="text" class="form-control" name="main_image" placeholder="Введите отностельный путь" id="imageInput" required>

            <label for="descriptionInput" class="form-label mt-3">Описание</label>
            <textarea class="form-control" rows="5" name="description" placeholder="Введите описание" id="descriptionInput" required></textarea>

            <label for="galleryInput" class="form-label mt-3">Название папки с изображениями</label>
            <input type="text" class="form-control" name="gallery_path" placeholder="Введите название папки" id="galleryInput" required>

            <button type="submit" class="submit-button mt-4 w-100">Добавить</button>
        </form>
    </div>
</template>

<style lang="scss" scoped>
.submit-button{
    background: #E61E24;
    color: #FFF;
    border: 0px;
    height: 60px;
    border-radius: 10px;
    
}
</style>

<script>
export default{

}
</script>