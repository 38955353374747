<template>
  <header>
    <div class="header" :class="{header_dark: userurl!=='/'}">
        <div class="header-left">
            <a href="/#main"><img src="../assets/img/company.png" alt="#"></a>
        </div>
        <div class="header-center">
            <a href="/#main">Главная</a>
            <a href="/#projects">Проекты</a>
            <a href="/#services">Услуги</a>
            <a href="/#advantages">Преимущества</a>
            <a href="/#clients">Клиенты</a>
            <a href="/#command">Команда</a>
            <a href="/#questions">Вопросы</a>
            <a href="#contacts">Контакты</a>
        </div>
        <div class="wrapper">
          <div class="head">
            <div class="container">
              <div class="header__body">
                <div class="header__logo">
                  <img src="../assets/img/company.png" alt="">
                </div>
                <div class="header__burger" @click="toggleMenu">
                  <img src="../assets/img/menu_header.png" alt="">
                </div>
                <nav class="header__menu" :class="{ active: isActive }">
                  <img src="../assets/img/header_back.png" alt="" id="header-back" @click="toggleMenu">
                  <ul class='header__list'>
                    <li>
                        <a href="/#main" class="header__link" @click="toggleMenu">Главная</a>
                    </li>
                    <li>
                        <a href="/#projects" class="header__link" @click="toggleMenu">Проекты</a>
                    </li>
                    <li>
                        <a href="/#services" class="header__link" @click="toggleMenu">Услуги</a>
                    </li>
                    <li>
                        <a href="/#advantages" class="header__link" @click="toggleMenu">Преимущества</a>
                    </li>
                    <li>
                        <a href="/#clients" class='header__link' @click="toggleMenu">Клиенты</a>
                    </li>
                    <li>
                        <a href="/#command" class="header__link" @click="toggleMenu">Команда</a>
                    </li>
                    <li>
                        <a href="/#questions" class="header__link" @click="toggleMenu">Вопросы</a>
                    </li>
                    <li>
                        <a href="#contacts" class="header__link" @click="toggleMenu">Контакты</a>
                    </li>
                    <li>
                        <a href="tel:+74951723693" class="menu-number"> +7 (495) 172-36-93 </a>
                    </li>
                    <li>
                      <div class="header-messangers">
                        <a href="https://api.whatsapp.com/send?phone=79685010009"><img src="../assets/img/whatsapp.png" alt=""> </a>
                        <a href="https://t.me/sk_elite_msk"><img src="../assets/img/telegram.png" alt=""> </a>
                        <a href="https://www.instagram.com/sk_elit?igsh=MWgxOHpwZWNld3hqaw=="><img src="../assets/img/instagram.png" alt=""> </a>
                        <a href="https://rutube.ru/channel/41368092/"><img src="../assets/img/youtube.png" alt=""> </a>
                      </div>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div class="header-right">
            <button onclick="window.location.href='https://t.me/sk_elite_msk'"><img src="../assets/img/telegram.png" alt="#"></button>
            <a href="tel:+74951723693">+7 (495) 172-36-93</a>
        </div>
    </div>
  </header>
  </template>
  
  <script>
  export default {
    data() {
      return {
        userUrl: window.location.pathname,
        isActive: false,
      }
      
    },
    methods: {
      toggleMenu() {
        this.isActive = !this.isActive;
      },
    }
  }
  </script>

<style lang="scss">
</style>