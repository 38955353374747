<template>
          <HeaderMenu/>
          <router-view/>
          <FooterMain/>
</template>

<style lang="scss">
  @import url('../src/assets/styles/style.scss');
</style>

<script>
import FooterMain from '../src/components/FooterMain.vue';
import HeaderMenu from '../src/components/HeaderMenu.vue';

export default { 
  components: {
    HeaderMenu,
    FooterMain,
  }
}
</script>