import { createRouter, createWebHistory } from 'vue-router'
import MainPage from '@/views/MainPage.vue'
import RealisedProjects from '@/views/RealisedProjects.vue'
import ProjectDetails from '@/views/ProjectDetails.vue'
import AdminPage from '@/views/AdminPage.vue'
const routes = [
  {
    path: '/',
    component: MainPage
  },
  {
    path: '/projects/:category',
    component: RealisedProjects,
    props: true
  },
  {
    path: '/projects/:category/:title',
    component: ProjectDetails,
    props: true
  },
  {
    path: '/admin/',
    component: AdminPage,
    props: true
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router