<template>
  <div class="footer-limiter" id="contacts">
    <div class="eleventh-block">
      <p>КОНТАКТЫ</p>
      <div class="eleventh-block-contacts">
        <div class="eleventh-block-first-contact">
          <p class="text-secondary fs-6">Телефон</p>
          <p style="margin-bottom: 120px">+7 (495) 172-36-93</p>
          <button type="button" class="btn btn-primary eleventh-block-first-contact" data-bs-toggle="modal" data-bs-target="#PopUpMain">Запросить звонок</button>
        </div>
        <div class="eleventh-block-second-contact">
          <p class="text-secondary fs-6">Почта</p>
          <a style="text-decoration: none; color: #FFF; font-size: 24px;"
            href="mailto:Info@sk-elite.ru">Info@sk-elite.ru</a>
          <p class="text-secondary fs-6 eleventh-block-class" style="margin-top: 80px">Адрес</p>
          <p>Москва, ул. Электродная, дом 11, строение 15, помещение 06</p>
        </div>
        <div class="eleventh-block-third-contact">
          <p class="text-secondary fs-6">Соц. сети</p>
          <div class="third-contact-images">
          <a href="https://api.whatsapp.com/send?phone=79685010009"><img src="../assets/img/whatsapp.png" alt=""> </a>
          <a href="https://t.me/sk_elite_msk"><img src="../assets/img/telegram.png" alt=""> </a>
          <a href="https://www.instagram.com/sk_elit?igsh=MWgxOHpwZWNld3hqaw=="><img src="../assets/img/instagram.png" alt=""> </a>
          <a href="https://rutube.ru/channel/41368092/"><img src="../assets/img/youtube.png" alt=""> </a>
          </div>
          <p class="text-secondary fs-6">Режим работы</p>
          <p>Пн-Пт с 10:00 до 18:00</p>
        </div>
      </div>
      <div class="eleventh-block-map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d2396.889919547388!2d37.623533138211855!3d55.757803754581666!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sru!2sru!4v1723597973860!5m2!1sru!2sru"
          width="67%" height="519" style="border:0;" allowfullscreen="" loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"></iframe>
        <form class="block-map-connect">
          <div class="map-connect-text">
            <p class="fs-2">СВЯЗАТЬСЯ С НАМИ</p>
            <p class="text-secondary lh-sm">Заполните форму ниже, и мы свяжемся <br> с вами для уточнения деталей задачи
            </p>
          </div>
          <form class="map-connect-form"  @submit.prevent="sendForm">
            <input type="text" id="name" name="name" placeholder="Имя" required class="input-field" v-model="formName">
            <input type="email" id="email" name="email" placeholder="e-mail" required class="input-field" v-model="formMail">
            <input type="text" id="phone" name="phone" v-model="phoneNumber" class="mask-phone input-field" v-mask="'+# (###) ###-##-##'" placeholder="+7 (999) 999-99-99" required>
            <button type="submit" class="submit-button">Оставить заявку</button>
            <p>Оставляя заявку, вы соглашаетесь с правилами <br> <a href="#">Политики конфиденциальности</a></p>
          </form>
        </form>
      </div>
    </div>
  </div>
  <footer>
    <div class="footer-left">
      <div class="footer-block-left">
        <div class="footer-top-left">
          <div class="footer-top-left-text">
            <a href="/#main"><img src="../assets/img/logo-footer.png" alt=""></a>
            <p class="lh-sm">ООО “СК ЭЛИТ” <br> ИНН 7720369563 <br> КПП 772001001 <br> ОГРН 1177746126810</p>
          </div>
          <button type="button" class="btn btn-primary" data-bs-toggle="modal"
            data-bs-target="#PopUpMain">Консультация</button>
        </div>
        <div class="footer-bottom-left">
          <p>© ООО “СК ЭЛИТ”, 2024, все права защищены️</p>
          <a href="#">Политика конфиденциальности</a>
        </div>
      </div>
      <div class="footer-middle-left lh-lg">
        <a href="/#main">Главная</a>
        <a href="/#projects">Проекты</a>
        <a href="/#services">Услуги</a>
        <a href="/#advantages">Преимущества</a>
        <a href="/#clients">Клиенты</a>
        <a href="/#command">Команда</a>
        <a href="/#licence">Лицензии</a>
        <a href="/#questions">Вопросы</a>
        <a href="#contacts">Контакты</a>
      </div>
    </div>
    <div class="footer-right">
      <div class="footer-middle-right lh-lg">
        <a href="/#projects">Ремонт офисов и коммерческой недвижимости</a>
        <a href="/#projects">Ремонт кинотеатров</a>
        <a href="/#projects">Ремонт банков</a>
        <a href="/#projects">Ремонт магазинов и шоурумов</a>
        <a href="/#projects">Ремонт бизнес-центров</a>
        <a href="/#projects">Ремонт гостиниц</a>
        <a href="/#projects">Ремонт ресторанов и баров</a>
        <a href="/#projects">Ремонт салонов красоты и СПА</a>
        <a href="/#projects">Ремонт производственных помещений</a>
      </div>
      <div class="footer-block-right">
        <p style="margin-bottom: 20px; margin-top: 0px">Свяжитесь с нами, если у вас есть <br> вопросы, или чтобы
          обсудить проект</p>
        <a href="tel:+74951723693">+7 (495) 172-36-93</a>
        <a href="mailto:Info@sk-elite.ru" style="margin-top: 15px;">Info@sk-elite.ru</a>
        <p>Москва, ул. Электродная, дом 11, <br> строение 15, помещение 06</p>
        <div class="footer-images">
          <a href="https://api.whatsapp.com/send?phone=79685010009"><img src="../assets/img/whatsapp.png" alt=""> </a>
          <a href="https://t.me/sk_elite_msk"><img src="../assets/img/telegram.png" alt=""> </a>
          <a href="https://www.instagram.com/sk_elit?igsh=MWgxOHpwZWNld3hqaw=="><img src="../assets/img/instagram.png" alt=""> </a>
          <a href="https://rutube.ru/channel/41368092/"><img src="../assets/img/youtube.png" alt=""> </a>
        </div>
      </div>
    </div>


    <div class="footer-invisible-1">
      <div class="footer-block-left">
        <div class="footer-top-left">
          <div class="footer-top-left-text">
            <a href="/#main"><img src="../assets/img/logo-footer.png" alt=""></a>
            <p class="lh-sm">ООО “СК ЭЛИТ” <br> ИНН 7720369563 <br> КПП 772001001 <br> ОГРН 1177746126810</p>
          </div>
          <button type="button" class="btn btn-primary" data-bs-toggle="modal"
            data-bs-target="#PopUpMain">Консультация</button>
        </div>
        <div class="footer-bottom-left">
          <p>© ООО “СК ЭЛИТ”, 2024, все права защищены️</p>
          <a href="#">Политика конфиденциальности</a>
        </div>
      </div>
      <div class="footer-block-right">
        <div class="footer-block-top-right">
          <div class="footer-top-right-first">
            <a href="/#main">Главная</a>
            <a href="/#projects">Проекты</a>
            <a href="/#services">Услуги</a>
            <a href="/#advantages">Преимущества</a>
            <a href="/#clients">Клиенты</a>
            <a href="/#command">Команда</a>
            <a href="/#questions">Вопросы</a>
            <a href="#contacts">Контакты</a>
          </div>
          <div class="footer-top-right-second">
            <a href="/#projects">Ремонт офисов и коммерческой недвижимости</a>
            <a href="/#projects">Ремонт кинотеатров</a>
            <a href="/#projects">Ремонт гостиниц</a>
            <a href="/#projects">Ремонт банков</a>
            <a href="/#projects">Ремонт магазинов и шоурумов</a>
            <a href="/#projects">Ремонт производственных помещений</a>
            <a href="/#projects">Ремонт салонов красоты и СПА</a>
            <a href="/#projects">Ремонт бизнес-центров</a>
            <a href="/#projects">Ремонт ресторанов и баров</a>
          </div>
        </div>
        <div class="footer-block-bottom-right">
          <div class="footer-bottom-right-first">
            <p>Свяжитесь с нами, если у вас есть <br> вопросы, или чтобы обсудить проект</p>
            <a href="tel:+74951723693">+7 (495) 172-36-93</a>
            <a href="mailto:Info@sk-elite.ru">Info@sk-elite.ru</a>
          </div>
          <div class="footer-bottom-right-second">
            <p>Москва, ул. Электродная, дом 11, <br> строение 15, помещение 06</p>
            <div class="footer-images">
              <a href="#"><img src="../assets/img/whatsapp.png" alt=""> </a>
              <a href="#"><img src="../assets/img/telegram.png" alt=""> </a>
              <a href="#"><img src="../assets/img/instagram.png" alt=""> </a>
              <a href="#"><img src="../assets/img/youtube.png" alt=""> </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="footer-invisible-2">
      <div class="footer-first-block">
        <div class="footer-first-block-1">
          <a href="/#main">Главная</a>
          <a href="/#projects">Проекты</a>
          <a href="/#services">Услуги</a>
          <a href="/#advantages">Преимущества</a>
          <a href="/#clients">Клиенты</a>
        </div>
        <div class="footer-first-block-2">
          <a href="/#command">Команда</a>
          <a href="/#licence">Лицензии</a>
          <a href="/#questions">Вопросы</a>
          <a href="#contacts">Контакты</a>
        </div>
      </div>
      <div class="footer-second-block">
        <a href="/#projects">Ремонт офисов и коммерческой недвижимости</a>
        <a href="/#projects">Ремонт кинотеатров</a>
        <a href="/#projects">Ремонт банков</a>
        <a href="/#projects">Ремонт магазинов и шоурумов</a>
        <a href="/#projects">Ремонт бизнес-центров</a>
        <a href="/#projects">Ремонт гостиниц</a>
        <a href="/#projects">Ремонт ресторанов и баров</a>
        <a href="/#projects">Ремонт салонов красоты и СПА</a>
        <a href="/#projects">Ремонт производственных помещений</a>
      </div>
      <div class="footer-third-block">
        <div class="footer-third-block-1">
          <p>ООО “СК ЭЛИТ”</p>
          <p>ИНН 7720369563</p>
          <p>КПП 772001001</p>
          <p>ОГРН 1177746126810</p>
        </div>
        <div class="footer-third-block-2">
          <a href="#"><img src="../assets/img/whatsapp.png" alt=""> </a>
          <a href="#"><img src="../assets/img/telegram.png" alt=""> </a>
          <a href="#"><img src="../assets/img/instagram.png" alt=""> </a>
          <a href="#"><img src="../assets/img/youtube.png" alt=""> </a>
        </div>
      </div>
      <div class="footer-fourth-block">
        <p>Свяжитесь с нами, если у вас есть <br> вопросы, или чтобы обсудить проект</p>
        <a href="tel:+74951723693">+7 (495) 172-36-93</a>
        <a href="mailto:Info@sk-elite.ru">Info@sk-elite.ru</a>
        <p>Москва, ул. Электродная, дом 11, <br> строение 15, помещение 06</p>
      </div>
      <div class="footer-fifth-block">
        <div class="footer-fifth-block-top">
          <a href="/#main"><img src="../assets/img/logo-footer.png" alt=""></a>
          <button type="button" class="btn btn-primary" data-bs-toggle="modal"
            data-bs-target="#PopUpMain">Консультация</button>
        </div>
        <div class="footer-fifth-block-bottom">
          <p>© ООО “СК ЭЛИТ”, 2024, все права защищены️</p>
          <a href="#">Политика конфиденциальности</a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { mask } from 'vue-the-mask';  
import axios from 'axios';

export default {
  directives: { mask },
       data() {
         return {
           phoneNumber: '',
           formName: '',
           formMail: ''
         };
       },
    methods: {
      sendTGMessage(message){
        const telegramBotToken = '6808300037:AAGvPRBQhx6cqn-QmTiNjTLyijxlQSmuFvo';
        const chatId = '-1002342697157';
        let data = {
            chat_id: chatId,
            text: message
        }
        axios.post(`https://api.telegram.org/bot${telegramBotToken}/sendMessage`, data)
      },
      sendForm(){
        this.sendTGMessage(`Новая заявка\n${this.formName}\n${this.formMail}\n${this.phoneNumber}`)
        alert('Заявка отправлена!')
      }
    }
}
</script>


<style></style>