<template>
    <div class="second-block" id="services"> 
        <div class="second-block-body">
            <p> ПОЛУЧИТЕ СМЕТУ НА РЕМОНТ ВАШЕГО <br> ПОМЕЩЕНИЯ, ОТВЕТИВ НА 5 ВОПРОСОВ </p>
            <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#PopUpQuestions"> Получите смету </button>
        </div>
    </div>
  </template>
  
  <script>
  export default {
  }
  </script>
  