<template>
  <div class="fourth-block">
    <div class="fourth-block-body">
      <div class="fourth-block-left">
        <div class="fourth-block-text">
          <p>Чек-лист</p>
          <p class="fw-normal lh-sm 5-keys check-text" style="font-size: clamp(12px, 2.2vw, 32px);">5 КЛЮЧЕВЫХ ЭТАПОВ УСПЕШНОГО РЕМОНТА КОММЕРЧЕСКОЙ НЕДВИЖИМОСТИ</p>
          <p>Скачать PDF-файл с описанием этапов проекта, <br> начиная от планирования и заканчивая завершением</p>
        </div>
      </div>
      <div class="fourth-block-right">
        <div class="fourth-block-image">
          <img src="../assets/img/fourth-list.png" alt="">
        </div>
        <div class="fourth-block-link">
          <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#PopUpCheckList">Скачать PDF файл</button>
        </div>
      </div>
    </div>
</div>
  </template>
  
  <script>
    export default {
    }
  </script>
  
  
  <style lang="scss">
  </style>