<template>
    <div class="popup" v-if="step === 1">
        <div class="container-1">
            <div class="question-container">
                <div class="header-bg">
                    <div class="header">
                        <div class="left">
                            <p>Получите смету на ремонт <br> вашего помещения</p>
                            <p style="font-size: 20px; line-height: 20px" class='bottom-header'>Ответьте на 5 простых
                                вопросов, и мы бесплатно вышлем <br> вам <span> подробную смету </span> на ремонт вашего
                                помещения</p>
                        </div>
                        <div class="right">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><img
                                    src="../assets/img/back-button.png" alt=""></button>
                        </div>
                    </div>
                </div>
                <div class="question-block">
                    <div class="question">
                        <p>1. Выберите тип вашего объекта</p>
                    </div>
                    <div class="answer-options">
                        <div class="answers-block">
                            <div class="answer" v-for="(option, index) in optionsTypes" :key="index"
                                @click="toggleActiveButtonTypes(index)">
                                <button v-show="!isActive || activeButtonIndex !== index">
                                    <img src="../assets/img/inactive-button.png" alt="">
                                </button>
                                <button v-show="activeButtonIndex === index">
                                    <img src="../assets/img/active-button.png" alt="">
                                </button>
                                <p>{{ option.text }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="next" @click="nextStep">
                    <img src="../assets/img/next.png" alt="">
                </div>
            </div>
        </div>
    </div>
    <div class="popup" v-if="step === 2">
        <div class="container-2">
            <div class="question-container">
                <div class="header-bg">
                    <div class="header">
                        <div class="left">
                            <p>Получите смету на ремонт <br> вашего помещения</p>
                            <p style="font-size: 20px; line-height: 20px" class='bottom-header'>Ответьте на 5 простых
                                вопросов, и мы бесплатно
                                вышлем <br> вам <span> подробную смету </span> на ремонт вашего помещения</p>
                        </div>
                        <div class="right">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                <img src="../assets/img/back-button.png" alt="">
                            </button>
                        </div>
                    </div>
                </div>
                <div class="question-block">
                    <div class="question">
                        <p>2. Какая площадь объекта, где требуется выполнить ремонт?</p>
                    </div>
                    <div class="answer-options">
                        <div class="answers-block">
                            <div class="answer" v-for="(option, index) in optionsSquare" :key="index">
                                <div v-if="index === 0">
                                    <input 
                                        v-model="squareInput" 
                                        :type="option.type" 
                                        :id="option.id" 
                                        :name="option.name" 
                                        :placeholder="option.placeholder" 
                                        required 
                                        class="input-field" 
                                        @input="toggleActiveButtonSquare(0)"> 
                                </div>
                                <div v-else>
                                    <button :class="{ active: activeButtonIndex === index }"
                                        @click="toggleActiveButtonSquare(index)">
                                        <img v-if="activeButtonIndex !== index" src="../assets/img/inactive-button.png" alt="">
                                        <img v-else src="../assets/img/active-button.png" alt="">
                                        <p>{{ option.text }}</p>
                                    </button>
                                </div>
                            </div> 
                        </div>
                      </div>            
                </div>
                <div class="next" @click="nextStep">
                    <img src="../assets/img/next.png" alt="">
                </div>
            </div>
        </div>
    </div>
    <div class="popup" v-if="step === 3">
        <div class="container-3">
            <div class="question-container">
                <div class="header-bg">
                    <div class="header">
                        <div class="left">
                            <p>Получите смету на ремонт <br> вашего помещения</p>
                            <p style="font-size: 20px; line-height: 20px" class='bottom-header'>Ответьте на 5
                                простых
                                вопросов, и мы бесплатно вышлем <br> вам <span> подробную смету </span> на ремонт
                                вашего
                                помещения</p>
                        </div>
                        <div class="right">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><img
                                    src="../assets/img/back-button.png" alt=""></button>
                        </div>
                    </div>
                </div>
                <div class="question-block">
                    <div class="question">
                        <p>3. Выберите вариант работ</p>
                    </div>
                    <div class="answer-options">
                        <div class="answers-block">
                            <div class="answer" v-for="(option, index) in optionsWorking" :key="index"
                                @click="toggleActiveButtonWorking(index)">
                                <button v-show="!isActive || activeButtonIndex !== index">
                                    <img src="../assets/img/inactive-button.png" alt="">
                                </button>
                                <button v-show="activeButtonIndex === index">
                                    <img src="../assets/img/active-button.png" alt="">
                                </button>
                                <p>{{ option.text }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="next" @click="nextStep">
                    <img src="../assets/img/next.png" alt="">
                </div>
            </div>
        </div>
    </div>
    <div class="popup" v-if="step === 4">
        <div class="container-4">
            <div class="question-container">
                <div class="header-bg">
                    <div class="header">
                        <div class="left">
                            <p>Получите смету на ремонт <br> вашего помещения</p>
                            <p style="font-size: 20px; line-height: 20px" class='bottom-header'>Ответьте на 5
                                простых
                                вопросов, и мы бесплатно вышлем <br> вам <span> подробную смету </span> на ремонт
                                вашего
                                помещения</p>
                        </div>
                        <div class="right">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><img
                                    src="../assets/img/back-button.png" alt=""></button>
                        </div>
                    </div>
                </div>
                <div class="question-block">
                    <div class="question">
                        <p>4. Когда планируется ремонт?</p>
                    </div>
                    <div class="answer-options">
                        <div class="answers-block">
                            <div class="answer" v-for="(option, index) in optionsRepair" :key="index"
                                @click="toggleActiveButtonRepair(index)">
                                <button v-show="!isActive || activeButtonIndex !== index">
                                    <img src="../assets/img/inactive-button.png" alt="">
                                </button>
                                <button v-show="activeButtonIndex === index">
                                    <img src="../assets/img/active-button.png" alt="">
                                </button>
                                <p>{{ option.text }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="next" @click="nextStep">
                    <img src="../assets/img/next.png" alt="">
                </div>
            </div>
        </div>
    </div>
    <div class="popup" v-if="step === 5">
        <div class="container-5">
            <div class="question-container">
                <div class="header-bg">
                    <div class="header">
                        <div class="left">
                            <p>Получите смету на ремонт <br> вашего помещения</p>
                            <p style="font-size: 20px; line-height: 20px" class='bottom-header'>Ответьте на 5
                                простых
                                вопросов, и мы бесплатно вышлем <br> вам <span> подробную смету </span> на ремонт
                                вашего
                                помещения</p>
                        </div>
                        <div class="right">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><img
                                    src="../assets/img/back-button.png" alt=""></button>
                        </div>
                    </div>
                </div>
                <div class="question-block">
                    <div class="question">
                        <p>5. Есть ли у вас дизайн-проект?</p>
                    </div>
                    <div class="answer-options">
                        <div class="answers-block">
                            <div class="answer" v-for="(option, index) in optionsDesign" :key="index"
                                @click="toggleActiveButtonDesign(index)">
                                <button v-show="!isActive || activeButtonIndex !== index">
                                    <img src="../assets/img/inactive-button.png" alt="">
                                </button>
                                <button v-show="activeButtonIndex === index">
                                    <img src="../assets/img/active-button.png" alt="">
                                </button>
                                <p>{{ option.text }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="next" @click="nextStep">
                    <img src="../assets/img/next.png" alt="">
                </div>
            </div>
        </div>
    </div>
    <div class="popup" v-if="step === 6">
        <div class="container-6">
            <div class="question-container">
                <div class="header-bg">
                    <div class="header">
                        <div class="left">
                            <p>Получите смету на ремонт <br> вашего помещения</p>
                            <p style="font-size: 20px; line-height: 20px" class='bottom-header'>Ответьте на 5
                                простых
                                вопросов, и мы бесплатно вышлем <br> вам <span> подробную смету </span> на ремонт
                                вашего
                                помещения</p>
                        </div>
                        <div class="right">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><img
                                    src="../assets/img/back-button.png" alt=""></button>
                        </div>
                    </div>
                </div>
                <div class="question-block">
                    <div class="question">
                        <p>Оставьте свои контактные данные для получения сметы</p>
                    </div>
                    <form class="input-container" @submit.prevent="sendForm">
                        <input type="text" id="name" name="name" placeholder="Имя" required class="input-field" v-model="formName">
                        <input type="text" id="phone" name="phone" v-model="phoneNumber" class="mask-phone input-field" v-mask="'+# (###) ###-##-##'" placeholder="+7 (999) 999-99-99" required>
                        <button data-bs-dismiss="modal" type="submit" class="submit-button">Оставить заявку</button>
                    </form>
                    <div class="politic">
                        <p>Оставляя заявку, вы соглашаетесь с правилами <br> <a href="#">Политики
                                конфиденциальности</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mask } from 'vue-the-mask';  
    import axios from 'axios';

    export default {
    directives: { mask },
    data() {
        return {
            step: 1,
            isActive: false,
            activeButtonIndex: null,
            phoneNumber: '',
            formName: '',
            optionsTypes: [
                { text: "Офис / коммерческая недвижимость", isSelected: false },
                { text: "Магазин / шоурум", isSelected: false },
                { text: "Ресторан / бар", isSelected: false },
                { text: "Салон красоты/ СПА", isSelected: false },
                { text: "Другое", isSelected: false },
            ],
            optionsSquare: [
                { input: true, type: "text", id: "aquare", name: "aquare", placeholder: "32 м2", isSelected: false },
                { text: "Не могу сказать точно", isSelected: false }
            ],
            optionsWorking: [
                { text: "Комплексный ремонт «под ключ»", isSelected: false },
                { text: "Изыскательные работы и проектирование", isSelected: false },
                { text: "Общестроительные и отделочные работы", isSelected: false },
                { text: "Другое", isSelected: false },
            ],
            optionsRepair: [
                { text: "В течение 2-3 дней", isSelected: false },
                { text: "Через неделю", isSelected: false },
                { text: "Через месяц", isSelected: false },
                { text: "В течение 3х месяцев", isSelected: false },
                { text: "Другое", isSelected: false },
            ],
            optionsDesign: [
                { text: "Да", isSelected: false },
                { text: "Нет", isSelected: false },
            ]
        };
    },
    methods: {
        toggleActiveButtonTypes(index) {
            if (this.activeButtonIndex !== index) {
                this.isActive = true;
                this.activeButtonIndex = index;
                this.optionsTypes[index].isSelected = !this.optionsTypes[index].isSelected;
            }
        },
        toggleActiveButtonSquare(index) {
            if (this.activeButtonIndex !== index) {
                this.isActive = true;
                this.activeButtonIndex = index;
                this.optionsSquare[index].isSelected = !this.optionsSquare[index].isSelected;
                if (index === 1) {
                    this.squareInput = '';
                }
            }
        },
        toggleActiveButtonWorking(index) {
            if (this.activeButtonIndex !== index) {
                this.isActive = true;
                this.activeButtonIndex = index;
                this.optionsWorking[index].isSelected = !this.optionsWorking[index].isSelected;
            }
        },
        toggleActiveButtonRepair(index) {
            if (this.activeButtonIndex !== index) {
                this.isActive = true;
                this.activeButtonIndex = index;
                this.optionsRepair[index].isSelected = !this.optionsRepair[index].isSelected;
            }
        },
        toggleActiveButtonDesign(index) {
            if (this.activeButtonIndex !== index) {
                this.isActive = true;
                this.activeButtonIndex = index;
                this.optionsDesign[index].isSelected = !this.optionsDesign[index].isSelected;
            }
        },
        nextStep() {
            if (!this.optionsTypes.some(option => option.isSelected)) {
                alert("Пожалуйста, выберите тип вашего объекта.");
            } else if ((!this.optionsSquare.some(option => option.isSelected) || (this.squareInput.length === 0 && this.activeButtonIndex !== 1)) && this.step === 2) {
                alert("Пожалуйста, напишите или укажите вариант ответа с Вашей площадью объекта.");
            } else if ((!this.optionsWorking.some(option => option.isSelected) && this.step === 3)) {
                alert("Пожалуйста, выберите вариант работы.");
            } else if ((!this.optionsRepair.some(option => option.isSelected) && this.step === 4)) {
                alert("Пожалуйста, выберите примерную дату ремонта.");
            } else if ((!this.optionsDesign.some(option => option.isSelected) && this.step === 5)) {
                alert("Пожалуйста, выберите дизайн.");
            } else {
                this.step++;
                this.isActive = false;
                this.activeButtonIndex = null; //обнуляем кнопку
            }
        },

        sendTGMessage(message){
            const telegramBotToken = '6808300037:AAGvPRBQhx6cqn-QmTiNjTLyijxlQSmuFvo';
            const chatId = '-1002342697157';
            let data = {
                chat_id: chatId,
                text: message
            }
            axios.post(`https://api.telegram.org/bot${telegramBotToken}/sendMessage`, data)
        },

        sendForm(){
            let squareAnswer = '';
            this.squareInput == '' ? squareAnswer='Не могу сказать точно' : squareAnswer=this.squareInput;
            this.sendTGMessage(`Новая заявка\n${this.formName}\n${this.phoneNumber}\n${this.optionsTypes.find(elem => elem.isSelected == true).text}\n${squareAnswer}\n${this.optionsWorking.find(elem => elem.isSelected == true).text}\n${this.optionsRepair.find(elem => elem.isSelected == true).text}\n${this.optionsDesign.find(elem => elem.isSelected == true).text}
            `)
            alert('Заявка отправлена!')
        }
    }
};
</script>

<style lang="scss" scoped>
.popup {
    padding: 150px 20px 0px 20px;
    position: fixed;
    height: 100%;
    width: 100%;
    color: #FFF;

    .container-1,
    .container-4 {
        display: flex;
        justify-content: center;

        .question-container {
            width: 760px;
            height: 490px;
            display: flex;
            flex-direction: column;
            position: relative;

            .header-bg {
                background-image: url(../assets/img/question-bg.png);
                background-position: center;
                background-size: 760px 270px;
                background-repeat: no-repeat;

                .header {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    height: 200px;
                    background: rgba(1, 1, 1, .8);
                    padding: 20px;

                    .left {
                        display: flex;
                        flex-direction: column;
                        gap: 35px;

                        p {
                            font-size: 40px;
                            line-height: 40px;
                            height: 50px;

                            span {
                                color: #E61E24;
                            }
                        }
                    }

                    .right {
                        button {
                            background: none;
                            border: 0px;
                            display: flex;
                            justify-content: flex-start;

                            img {
                                width: 20px;
                                height: 20px;
                            }
                        }
                    }
                }

                .bottom-text {
                    height: 70px;
                    padding-left: 20px;
                    background: rgba(1, 1, 1, .6);
                }
            }

            .question-block {
                background: #0F0E0E;
                position: relative;
                height: 250px;
                .question {
                    padding: 35px;

                    p {
                        font-size: 32px;
                    }
                }

                .answer-options {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    flex-wrap: wrap;
                    padding-bottom: 30px;
                    padding-left: 35px;

                    .answers-block {
                        display: flex;
                        flex-direction: row !important;
                        flex-wrap: wrap;
                        column-gap: 50px;
                        row-gap: 25px;
                    }

                    .answer {
                        display: flex;
                        flex-direction: row;

                        p {
                            margin-bottom: 0;
                            padding-left: 15px;
                            cursor: pointer;
                        }

                        button {
                            display: flex;
                            align-items: start;
                            border: 0px;
                            background: none;
                            padding: 0;
                            height: 25px;
                            cursor: pointer;

                            img {
                                width: 22px;
                                height: 22px;
                            }
                        }
                    }
                }
            }

            .next {
                position: absolute;
                right: 20px;
                bottom: 70px;
                cursor: pointer;

                img {
                    width: 30px;
                    height: auto;
                }
            }
        }
    }

    .container-2 {
        display: flex;
        justify-content: center;

        .question-container {
            width: 760px;
            height: 490px;
            display: flex;
            flex-direction: column;
            position: relative;

            .header-bg {
                background-image: url(../assets/img/question-bg.png);
                background-position: center;
                background-size: 760px 270px;
                background-repeat: no-repeat;

                .header {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    height: 200px;
                    background: rgba(1, 1, 1, .8);
                    padding: 20px;

                    .left {
                        display: flex;
                        flex-direction: column;
                        gap: 35px;

                        p {
                            font-size: 40px;
                            line-height: 40px;
                            height: 50px;

                            span {
                                color: #E61E24;
                            }
                        }
                    }

                    .right {
                        button {
                            background: none;
                            border: 0px;
                            display: flex;
                            justify-content: flex-start;
                            width: min-content;
                            padding: 0 !important;

                            img {
                                width: 20px;
                                height: 20px;
                            }
                        }
                    }
                }

                .bottom-text {
                    height: 70px;
                    padding-left: 20px;
                    background: rgba(1, 1, 1, .6);
                }
            }

            .question-block {
                background: #0F0E0E;

                .question {
                    padding: 35px;
                    padding-bottom: 10px;

                    p {
                        font-size: 32px;
                    }
                }

                                .answer-options {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    flex-wrap: wrap;
                    padding-bottom: 30px;
                    padding-left: 35px;

                    .answers-block {
                        display: flex;
                        flex-direction: row !important;
                        flex-wrap: wrap;
                        column-gap: 40px;
                        row-gap: 20px;
                    }

                    .answer {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        margin: 0;

                        P {
                            margin: 0;
                            margin-left: 10px;
                            color: #FFF;
                        }

                        input {
                            background: #0F0E0E;
                            border: 1px solid #FFF;
                            padding-left: 20px;
                            border-radius: 10px;
                            caret-color: #FFF;
                            color: #FFF;
                            height: 50px;
                        }

                        button {
                            display: flex;
                            align-items: start;
                            border: 0px;
                            background: none;
                            padding: 0;

                            img {
                                width: 22px;
                                height: 22px;
                            }
                        }
                    }
                }
            }

            .next {
                position: absolute;
                right: 20px;
                bottom: 65px;
                cursor: pointer;

                img {
                    width: 30px;
                    height: auto;
                }
            }
        }
    }

    .container-3 {
        display: flex;
        justify-content: center;

        .question-container {
            width: 760px;
            height: 490px;
            display: flex;
            flex-direction: column;
            position: relative;

            .header-bg {
                background-image: url(../assets/img/question-bg.png);
                background-position: center;
                background-size: 760px 270px;
                background-repeat: no-repeat;

                .header {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    height: 200px;
                    background: rgba(1, 1, 1, .8);
                    padding: 20px;

                    .left {
                        display: flex;
                        flex-direction: column;
                        gap: 35px;

                        p {
                            font-size: 40px;
                            line-height: 40px;
                            height: 50px;

                            span {
                                color: #E61E24;
                            }
                        }
                    }

                    .right {
                        button {
                            background: none;
                            border: 0px;
                            display: flex;
                            justify-content: flex-start;

                            img {
                                width: 20px;
                                height: 20px;
                            }
                        }
                    }
                }

                .bottom-text {
                    height: 70px;
                    padding-left: 20px;
                    background: rgba(1, 1, 1, .6);
                }
            }

            .question-block {
                background: #0F0E0E;

                .question {
                    padding: 35px;

                    p {
                        font-size: 32px;
                    }
                }

                .answer-options {
                    display: flex;
                    flex-direction: column;
                    gap: 0px;
                    gap: 20px;
                    flex-wrap: wrap;
                    padding-bottom: 30px;

                    .answers-block {
                        display: flex;
                        flex-direction: row !important;
                        flex-wrap: wrap;
                        row-gap: 10px;
                    }

                    .answer {
                        display: flex;
                        flex-direction: row;
                        padding-left: 40px;

                        p {
                            padding-left: 15px;
                            cursor: pointer;
                        }

                        button {
                            display: flex;
                            align-items: start;
                            border: 0px;
                            background: none;
                            padding: 0;
                            height: 25px;
                            cursor: pointer;

                            img {
                                width: 22px;
                                height: 22px;
                            }
                        }
                    }
                }
            }

            .next {
                position: absolute;
                right: 20px;
                bottom: 60px;
                cursor: pointer;

                img {
                    width: 30px;
                    height: auto;
                }
            }
        }
    }

    .container-4 {
        display: flex;
        justify-content: center;

        .question-container {
            width: 760px;
            height: 490px;
            display: flex;
            flex-direction: column;
            position: relative;

            .header-bg {
                background-image: url(../assets/img/question-bg.png);
                background-position: center;
                background-size: 760px 270px;
                background-repeat: no-repeat;

                .header {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    height: 200px;
                    background: rgba(1, 1, 1, .8);
                    padding: 20px;

                    .left {
                        display: flex;
                        flex-direction: column;
                        gap: 35px;

                        p {
                            font-size: 40px;
                            line-height: 40px;
                            height: 50px;

                            span {
                                color: #E61E24;
                            }
                        }
                    }

                    .right {
                        button {
                            background: none;
                            border: 0px;
                            display: flex;
                            justify-content: flex-start;

                            img {
                                width: 20px;
                                height: 20px;
                            }
                        }
                    }
                }

                .bottom-text {
                    height: 70px;
                    padding-left: 20px;
                    background: rgba(1, 1, 1, .6);
                }
            }

            .question-block {
                background: #0F0E0E;
                position: relative;
                height: 250px;
                .question {
                    padding: 35px;

                    p {
                        font-size: 32px;
                    }
                }

                .answer-options {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    flex-wrap: wrap;
                    padding-bottom: 30px;
                    padding-left: 35px;

                    .answers-block {
                        display: flex;
                        flex-direction: row !important;
                        flex-wrap: wrap;
                        column-gap: 40px;
                        row-gap: 25px;
                        width: 520px;
                    }

                    .answer {
                        display: flex;
                        flex-direction: row;

                        p {
                            margin-bottom: 0;
                            padding-left: 15px;
                            cursor: pointer;
                        }

                        button {
                            display: flex;
                            align-items: start;
                            border: 0px;
                            background: none;
                            padding: 0;
                            height: 25px;
                            cursor: pointer;

                            img {
                                width: 22px;
                                height: 22px;
                            }
                        }
                    }
                }
            }

            .next {
                position: absolute;
                right: 20px;
                bottom: 70px;
                cursor: pointer;

                img {
                    width: 30px;
                    height: auto;
                }
            }
        }
    }


    .container-5 {
        display: flex;
        justify-content: center;

        .question-container {
            width: 760px;
            height: 490px;
            display: flex;
            flex-direction: column;
            position: relative;

            .header-bg {
                background-image: url(../assets/img/question-bg.png);
                background-position: center;
                background-size: 760px 270px;
                background-repeat: no-repeat;

                .header {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    height: 200px;
                    background: rgba(1, 1, 1, .8);
                    padding: 20px;

                    .left {
                        display: flex;
                        flex-direction: column;
                        gap: 35px;

                        p {
                            font-size: 40px;
                            line-height: 40px;
                            height: 50px;

                            span {
                                color: #E61E24;
                            }
                        }
                    }

                    .right {
                        button {
                            background: none;
                            border: 0px;
                            display: flex;
                            justify-content: flex-start;

                            img {
                                width: 20px;
                                height: 20px;
                            }
                        }
                    }
                }

                .bottom-text {
                    height: 70px;
                    padding-left: 20px;
                    background: rgba(1, 1, 1, .6);
                }
            }

            .question-block {
                background: #0F0E0E;
                height: 250px;
                .question {
                    padding: 35px;
                    padding-bottom: 10px;

                    p {
                        font-size: 32px;
                    }
                }

                .answer-options {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    flex-wrap: wrap;
                    padding-bottom: 30px;
                    padding-left: 20px;

                    .answers-block {
                        display: flex;
                        flex-direction: row !important;
                        flex-wrap: wrap;
                    }

                    .answer {
                        display: flex;
                        flex-direction: row;
                        padding-left: 40px;
                        align-items: center;
                        margin: 0;

                        P {
                            margin: 0;
                            padding-left: 10px;
                            cursor: pointer;
                        }

                        button {
                            display: flex;
                            align-items: start;
                            border: 0px;
                            background: none;
                            padding: 0;
                            cursor: pointer;

                            img {
                                width: 22px;
                                height: 22px;
                            }
                        }
                    }
                }
            }

            .next {
                position: absolute;
                right: 20px;
                bottom: 55px;
                cursor: pointer;

                img {
                    width: 30px;
                    height: auto;
                }
            }
        }
    }

    .container-6 {
        display: flex;
        justify-content: center;

        .question-container {
            width: 760px;
            height: 490px;
            display: flex;
            flex-direction: column;

            .header-bg {
                background-image: url(../assets/img/question-bg.png);
                background-position: center;
                background-size: 760px 270px;
                background-repeat: no-repeat;

                .header {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    height: 200px;
                    background: rgba(1, 1, 1, .8);
                    padding: 20px;

                    .left {
                        display: flex;
                        flex-direction: column;
                        gap: 35px;

                        p {
                            font-size: 40px;
                            line-height: 40px;
                            height: 50px;

                            span {
                                color: #E61E24;
                            }
                        }
                    }

                    .right {
                        button {
                            background: none;
                            border: 0px;
                            display: flex;
                            justify-content: flex-start;

                            img {
                                width: 20px;
                                height: 20px;
                            }
                        }
                    }
                }

                .bottom-text {
                    height: 70px;
                    padding-left: 20px;
                    background: rgba(1, 1, 1, .6);
                }
            }

            .question-block {
                background: #0F0E0E;

                .question {
                    padding: 35px;
                    padding-bottom: 10px;

                    p {
                        font-size: 32px;
                    }
                }

                .input-container {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    max-width: 450px;
                    padding-left: 30px;
                    gap: 10px;

                    #name {
                        border: 1.5px solid #FFF;
                        border-radius: 10px;
                        background: #000;
                        color: #FFF;
                        padding-left: 20px;
                        width: 220px;
                        height: 60px;
                    }

                    #phone {
                        border: 1.5px solid #FFF;
                        border-radius: 10px;
                        background: #000;
                        color: #FFF;
                        padding-left: 20px;
                        width: 220px;
                        height: 60px;
                    }

                    button {
                        background: #E61E24;
                        color: #FFF;
                        border: 0px;
                        height: 60px;
                        border-radius: 10px;
                        min-width: 220px;
                        display: flex;
                        justify-content: center;
                        align-items: center
                    }

                }

                .politic {
                    p {
                        width: 300px;
                        font-size: 12px;
                        color: #D6D6D6 !important;
                        line-height: 13px;
                        padding: 15px 0px 20px 30px;
                        margin: 0;

                        a {
                            color: #D6D6D6 !important;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 720px) {
    .popup {
        display: flex;
        justify-content: center;

        .container-1,
        .container-2,
        .container-3,
        .container-4,
        .container-5,
        .container-6 {
            width: 300px;
            height: 675px;
            
            .bottom-header {
                font-size: 14px !important;
                line-height: normal !important;
            }

            .question-container {
                height: 350px;
                width: 300px;

                .header-bg {
                    background-size: 900px 350px;

                    .header {
                        height: 250px;

                        .left {
                            padding-top: 90px;
                            gap: 5px;

                            p {
                                font-size: 18px;
                                line-height: 20px;
                                margin: 0;
                            }
                        }
                    }
                }

                .question-block {
                    min-height: 450px;
                    .question {
                        padding: 20px;
                    }

                    .answer-options {
                        padding-left: 10px;

                        .answers-block {
                            flex-direction: column !important;
                            gap: 30px;

                            .answer {
                                padding-left: 10px;
                            }
                        }
                    }

                    .question {
                        p {
                            font-size: 20px !important;
                        }
                    }

                    .input-container {
                        flex-direction: column;
                        padding-left: 20px;

                        button {
                            width: 220px;
                        }
                    }
                }

                .next {
                    bottom: -330px;
                }
            }
        }
        .container-6 {
            .question-container {
                .question-block {
                    .input-container {
                        #name,
                        #phone {
                            width: 93%;
                        }
                        button {
                            width: 93%;
                        }
                    }
                    .politic {
                        margin-top: 15px;
                        p {
                            padding: 0;
                            text-align: center;
                        }    
                    }
                }
            }
        }
    }
}
</style>